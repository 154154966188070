// import npm packge
import _ from 'lodash';
import $ from 'jquery';
import * as moment from "moment";
import { toast } from 'react-toastify';
// import service api
//
// import core class
import { API_ROOT, DURATION_TOAST, PREFIX_LOCAL, USING_MOBILE } from './constant';
// import com page
//
// import modal tool
//
// import style media
//
// coding
export default class Util {
    /*Static method*/
    static generateTestList = function($env, num, numItems) {
        let _seft = this;
        let entry = { name: 'droppable' + num + 'Items', items: [], index: num };
        const randomSize = () => 50 + Math.floor(Math.random() * Math.floor(250));
        const pseudoRandomSize = i =>
            50 + (((i + 1) * (num + 1)) % 5 === 0 ? 200 : ((i + 1) * (num + 1)) % 4 === 0 ? 150 : ((i + 1) * (num + 1)) % 3 === 0 ? 100 : ((i + 1) * (num + 1)) % 2 === 0 ? 50 : 0);
        let sectionId = 0;
        for (let i = 0; i < numItems; i++) {
            if (i % 3 === 0) {
                sectionId = i;
            }
            entry.items.push({ id: num + '_' + i, name: 'Item ' + num + '-' + i, height: $env.state.lazyLoad ? pseudoRandomSize(i) : randomSize(), sectionId: 'Person ' + sectionId / 3 });
        }
        return entry;
    };
    static indexColorbyId($colors, $id) {
        let $idx = _.findIndex($colors, {
            id: $id
        });
        if ($idx != -1) {
            return $colors[$idx].hexcode;
        }
        return "";
    };
    static indexTagbyId($tags, $id) {
        let $idx = _.findIndex($tags, {
            id: $id
        });
        if ($idx != -1) {
            return $tags[$idx];
        }
        return false;
    };
    static listIconBy($icon, $mode = 'default', $name = 'Default') {
        let lstdefault = _.reject($icon, function(items) {
            return $mode == 'default' || $mode == 'each' ? items.name != $name : items.name == null;
        });
        if (lstdefault.length == 1 && ($mode == 'default' || $mode == 'each')){
            return lstdefault[0].items;
        }
        if ($mode == 'more'){
            return lstdefault;
        }
        return [];
    };
    static getFullname($users, $uid) {
        let _find = _.find($users, { 'uid': $uid});
        if (_find) {
            return _find.fullname;
        }
        return 'N/A';
    };
    static indexMembers($users, $uid) {
        let $idx = _.findIndex($users, { 'uid': $uid});
        if ($idx != -1) {
            return $idx;
        }
        return false;
    };
    static convertNameToText(fullname) {
        const _name_array = String(fullname).split(" ");
        const _name_str = (_name_array.length > 1 ? (String(_name_array[0]).substring(0, 1) + String(_name_array[_name_array.length - 1]).substring(0, 1)) : String(_name_array[0]).substring(0, 2)).toUpperCase();
        return _name_str;
    };
    static formatDateTime($date, $format = 'YYYY-MM-DD') {
        return moment($date).format($format.toUpperCase());
    };
    static toast($msg, $type = 'success', $promise) {
        const attr = {
            position: "bottom-left",
            autoClose: DURATION_TOAST,
            hideProgressBar: false,
            // closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            closeButton: true,
            pauseOnFocusLoss:false,
            pauseOnHover:false
        }
        switch($type){
            case 'skip':
                break;
            case 'success':
                toast.success($msg, attr);
                break;
            case 'info':
                toast.info($msg, attr);
                break;
            case 'error':
            case 'deny':
                toast.error($msg, attr);
                break;
            case 'warning':
                toast.warn($msg, attr);
                break;
            case 'promise':
                toast.promise(
                    $promise,
                    {
                        pending: $msg.loading,
                        success: $msg.success,
                        error: $msg.error,
                    }, 
                    attr
                );
                break;
            case 'loading':
                const toast_loading = toast.loading($msg, {
                    draggable: true,
                    position: "bottom-left",
                });
                return toast_loading;
                break;
            case 'update':
                // $promise is toast_loading object from loading key
                toast.dismiss($promise);
                toast.success($msg, attr);
                break;
            default:
                toast($msg, attr);
                break;
        }
    };
    static hex2Rgb = (hex, opa = 100) => {
        const rgbChar = ['r', 'g', 'b', 'rgb'];
        const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
        if (normal) {
            return normal.slice(1).reduce((a, e, i) => {
                a[rgbChar[i]] = parseInt(e, 16);
                a['rgb'] = (a['rgb'] != undefined ? a['rgb'] : "rgb(") + a[rgbChar[i]] + (rgbChar[i] !== "b" ? " " : " / " + opa + "%)");
                return a;
            }, {});
        }
        const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
        if (shorthand) {
            return shorthand.slice(1).reduce((a, e, i) => {
                a[rgbChar[i]] = 0x11 * parseInt(e, 16);
                a['rgb'] = (a['rgb'] != undefined ? a['rgb'] : "rgb(") + a[rgbChar[i]] + (rgbChar[i] !== "b" ? " " : " / " + opa + "%)");
                return a;
            }, {});
        }
        return null;
    };
    static renderNumberPlus = (num) => {
        let number = num ? num : 0;
        return String(number).length > 2 ? '99+' : String(number);
    };
    static calculateTime = ($range = []) => {
        let tracking = 0;
        let hour = 0;
        let minute = 0;
        let second = 0;
        if (!Array.isArray($range)) {
            return [false, [], 0];
        }
        _.forEach($range, function(time, i){
            let remain = 0;
            if (!time.end_time) {
                remain = moment() - moment(time.start_time);
            } else {
                remain = moment(time.end_time) - moment(time.start_time);
            }
            tracking += remain;
        });
        return [$range.length && !$range[$range.length - 1].end_time ? true : false, $range, Math.ceil(tracking/1000)];
    };
    static getAPIPrefix = () => {
        return API_ROOT + '/' + JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'fe_domain'));
    };
    static getHeaders = () => {
        let access_token = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'access_token'));
        let username = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'username'));
        let hashtoken = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'hashtoken'));
        let verify_code = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'verify_code'));
        let fe_domain = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'fe_domain'));
        let langcode = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'langcode'));
        let uid = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'user_id'));
        let is_owner = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'is_owner'));
        return { headers: { "Authorization": access_token, "Verify-Code": verify_code, "Fedomain": fe_domain, "Username": username, "Langcode": langcode, "Uid": uid, "Is-Owner": is_owner } };
    };
    static clearLocalData = () => {
        const access_token = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'access_token'));
        if (access_token) {
            localStorage.removeItem(PREFIX_LOCAL + 'access_token');
            localStorage.removeItem(PREFIX_LOCAL + 'verify_code');
            localStorage.removeItem(PREFIX_LOCAL + 'fe_domain');
            localStorage.removeItem(PREFIX_LOCAL + 'username');
            localStorage.removeItem(PREFIX_LOCAL + 'hashtoken');
            localStorage.removeItem(PREFIX_LOCAL + 'user_id');
            localStorage.removeItem(PREFIX_LOCAL + 'is_owner');
        }
    };
    static dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
        while(n--){
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, {type:mime});
    };
    static saveFileUnderPopup = ($url, filename = null, $token = '', $sessionid = '') => {
        var _filename = filename ? filename : $url.substring($url.lastIndexOf("/") + 1).split("?")[0];
        var _xhr = new XMLHttpRequest();
        _xhr.responseType = 'blob';
        _xhr.onload = function() {
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(_xhr.response); // _xhr.response is a blob
            a.download = _filename; // Set the file name.
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };
        _xhr.open('GET', $url + '?token=' + $token + '&sessionid=' + $sessionid);
        let access_token = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'access_token'));
        let username = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'username'));
        let hashtoken = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'hashtoken'));
        let verify_code = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'verify_code'));
        let fe_domain = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'fe_domain'));
        let langcode = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'langcode'));
        let uid = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'user_id'));
        let is_owner = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'is_owner'));
        _xhr.setRequestHeader('Content-Type', 'application/x-www-form-$urlencoded; charset=UTF-8');
        _xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        _xhr.setRequestHeader('Authorization', access_token);
        _xhr.setRequestHeader('Verify-Code', verify_code);
        _xhr.setRequestHeader('Fedomain', fe_domain);
        _xhr.setRequestHeader('Username', username);
        _xhr.setRequestHeader('Langcode', langcode);
        _xhr.setRequestHeader('Uid', uid);
        _xhr.setRequestHeader('Is-Owner', is_owner);
        _xhr.send();
    };
    static Linkify = ($inputContent) => {
        let replaceFull = $inputContent.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, "$1");
        replaceFull = replaceFull.replace(/&nbsp;/gi," ");
        replaceFull = replaceFull.replace(/target="_blank">/gi," ");
        //-- remove all inside SCRIPT and STYLE tags
        replaceFull = replaceFull.replace(/<script.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/script>/gi, "");
        replaceFull = replaceFull.replace(/<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gi, "");
        let replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        let replacedHyperlink = replaceFull.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
        //URLs starting with www. (without // before it, or it'd re-link the ones done above)
        let replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedHyperlink = replacedHyperlink.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
        //Change email addresses to mailto:: links
        let replacePattern3 = /(([a-zA-Z0-9_\-\.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim;
        replacedHyperlink = replacedHyperlink.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
        return replacedHyperlink;
    };
    static getCalendarConfig = (language) => {
        let objConfig = {};
        moment.updateLocale(language === 'EN' ? 'en-gb' : 'vi', {
            meridiem: function (hours, minutes, isLower) {
                let _format = hours < 12 ? 'am' : 'pm';
                return isLower ? _format : _format.toUpperCase();
            }
        });
        if (language === 'EN') {
            objConfig = {
                sameDay: '[Today] at dddd DD/MM/YYYY HH:mm A',
                // nextDay: '[Tomorrow]',
                // nextWeek: 'dddd',
                lastDay: '[Yesterday] at dddd DD/MM/YYYY HH:mm A',
                // lastWeek: '[Last] dddd DD/MM/YYYY HH:mm A',
                sameElse: 'dddd DD/MM/YYYY HH:mm A'
            }
        } else {
            objConfig = {
                sameDay: 'dddd DD/MM/YYYY HH:mm A [hôm nay]',
                // nextDay: '[ngày mai]',
                // nextWeek: 'dddd',
                lastDay: 'dddd DD/MM/YYYY HH:mm A [hôm qua]',
                // lastWeek: 'dddd DD/MM/YYYY HH:mm A [tuần trước]',
                sameElse: 'dddd DD/MM/YYYY HH:mm A'
            }
        }
        return objConfig;
    };
    static getHrefValue = ($inputContent) => {
        const regex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g;
        let href = [];
        let m;
        while ((m = regex.exec($inputContent)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            // The result can be accessed through the `m`-variable.
            m.forEach((match, groupIndex) => {
                if (groupIndex == 2) {
                    href.push(match);

                }
            });
        }
        return Array.from(new Set(href));;
    };
    static screenFriendly = ($case = '') => {
        switch($case){
            case 'task-detail':
                if (USING_MOBILE && window.innerWidth < 450){
                    return window.innerWidth - 210;
                }
                else {
                    return 'var(--pix-w-240)';
                }
            case 'advance-filter':
            default:
                if (USING_MOBILE && window.innerWidth < 420){
                    return window.innerWidth - 72;
                }
                else {
                    if ($case == 'advance-filter'){
                        return 'var(--pix-w-328)';
                    }
                    else {
                        return 288;
                    }
                }
        }
    };
    /*Public method*/
    ////////
    buildFormData = function(formData, data, parentKey = '') {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;
            formData.append(parentKey, value);
        }
    };
    jsonToFormData = function(data) {
        let formData = new FormData();
        this.buildFormData(formData, data);
        return formData;
    };
    handleResizeImage = async function(e, MAX_WIDTH = 300, MAX_HEIGHT = 300) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var img = document.createElement("img");
            img.onload = () => {
                var canvas = document.createElement('canvas');
                var width = img.width;
                var height = img.height;
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                var dataurl = canvas.toDataURL("image/png");
                return this.dataURLtoFile(dataurl, file.name);
            }
            img.src = e.target.result;
        }
        await reader.readAsDataURL(file);
    };
}