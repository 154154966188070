// import npm packge
import _ from 'lodash';
import $ from 'jquery';
import { toast } from 'react-toastify';
import {ObjectData, ListIcons, ListColors, ListTags, ListWinfos, ListMembers, Trans} from "./wmsData";
import {SEARCH_COUNTDOWN, DURATION_TOAST, PREFIX_LOCAL, GLOBAL} from "./constant";
import { publish } from "./events"
// import service api
import ServiceConfig  from '../services/ServiceConfig';
import ServiceAuth  from '../services/ServiceAuth';
import ServiceUser  from '../services/ServiceUser';
import ServiceWinfo  from '../services/ServiceWinfo';
import ServiceSinfo  from '../services/ServiceSinfo';
import ServiceTinfo  from '../services/ServiceTinfo';
import ServiceWtags  from '../services/ServiceWtags';
import ServiceWidgets  from '../services/ServiceWidgets';
// import core class
import Util from '../core/util';
// import com page
//
// import modal tool
//
// import style media
//
// coding
export default function wmsCls() {
	this.initial = async function($react) {
		this.react = $react;
		this.react.state = {
			//
		};
		this.objData = ObjectData;
		this.lstIcons = ListIcons;
		this.lstColors = ListColors;
		this.lstConstants = [];
		this.lstWidgetpacks = [];
		this.lstTags = ListTags;
		this.lstWinfos = ListWinfos;
		this.lstMembers = ListMembers;
		this.Trans = Trans;
		this.rightFilterShowing = false;
		this.comStage = null;
		this.loadingSeq = false;
		this.stopRecurStage = false;
		this.onsubmit = true;
		this.delayloading = false;
        this.prevent_continuity = false;
		this.stopChangeResultInprocess = false;
		this.initLoadTemplateReport = false;
		this.onTinfoDetail = false;
		this.taskWorklogs = [];
		this.onIndexColum = 0;
		this.handleSharePaste = function(event){
			console.log(event);
		};
		this.handleRealTaskDetail = ($elm_modal_id, $callback) => {
	        return $callback($elm_modal_id);
	    }
		await this;
	};
	this.renderWidth = function($r_show){
		let _self = this;
		_self.rightFilterShowing = !$r_show;
		if (!_self.rightFilterShowing){
			_self.clearFilter();
		} else {
			_self.comStage.setState({rightFilterShowing: !$r_show});
		}
	};
	this.clearFilter = function(){
		let _self = this;
		let filter = Object.assign({}, _self.comStage.state.objData.filter);
		filter.stage_step = [];
        filter.status = 'active';
        filter.checklist_result = [];
        filter.watches = [];
        filter.tags = [];
        filter.dateline = 'created';
        filter.inputsearch = '';
        filter.template = _self.comStage.state.objData.templateActive ? '' : 'default';
        filter.date = {};
        filter.clear = true;
		const comState = {
	        ..._self.comStage.state,
	        objData: {
	            ..._self.comStage.state.objData,
	            filter: filter
	        },
	        rightFilterShowing: _self.rightFilterShowing
	    }
	    _self.comStage.setState(comState);
	    _self.react.setState({wms_class: _self});
	    setTimeout(async function(){
	    	GLOBAL.resetTimer();
    		await _self.onSearchTinfos();
    	}, 0);
	};
	this.checkLogin = function($callback){
		let _self = this;
		ServiceAuth.instance.checklogin().then(async ({ data }) => {
	        if (data.status) {
	        	_self.objData.profiles.uid = data.member.uid;
	            _self.objData.profiles.fullname = data.member.fullname;
	            _self.objData.profiles.email = data.member.username;
	            _self.objData.profiles.avatar = data.member.avatar;
	            _self.objData.profiles.user_role = data.member.user_role;
	            _self.objData.profiles.accessfield = data.member.accessfield;
	            _self.objData.profiles.language = (data.lang).toUpperCase();
	            let onTracking = Util.calculateTime(data.tracking);
	            _self.objData.tasktime = onTracking[1];
	            _self.objData.isRunning = onTracking[0];
	            _self.objData.task_id = data.task_id;
	            _self.objData.workspace = data.workspace;
	            _self.Trans = data.trans;
	            const profileStage = {
			        ..._self.comStage.state,
			        isLogin: true,
			        objData: {
			            ..._self.comStage.state.objData,
			            profiles: {
			                	uid: data.member.uid,
			                	fullname: data.member.fullname,
						        // shortname: 'CN',
						        avatar: data.member.avatar,
						        email: data.member.username,
						        user_role: data.member.user_role,
						        accessfield: data.member.accessfield,
						        // role: 'Administrator',
						        language: (data.lang).toUpperCase()
			            },
			            isRunning: onTracking[0],
			            tasktime: onTracking[1],
			            task_id: data.task_id,
			            workspace: data.workspace
			        },
			        Trans: data.trans,
			    }
			    _self.comStage.setState(profileStage);
			    return $callback(_self.comStage);
	        } else {
	            return $callback(false, _self.comStage);
	        }
	    })
	    .catch((e) => {
	    	if(_self.comStage){
	    		_self.comStage.setState({isLogin: false});
	    		return $callback(false, _self.comStage);
	    	}
	    	else {
	    		window.location.assign("access");
	    	}
	    });
	};
	this.getLstColors = function() {
		let _self = this;
        ServiceConfig.instance.listColor().then(({ data }) => {
            if (data.status) {
            	_self.lstColors = data.data
            	const colorStage = {
			        ..._self.comStage.state,
			        lstColors: data.data
			    }
			    _self.comStage.setState(colorStage);
            } else {
                Util.toast(data.error, 'error');
            }
        })
    };
    this.getLstIcons = function() {
    	let _self = this;
        ServiceConfig.instance.listIconByCate().then(({ data }) => {
            if (data.status) {
            	_self.lstIcons = data.data
                const iconStage = {
			        ..._self.comStage.state,
			        lstIcons: data.data
			    }
			    _self.comStage.setState(iconStage);
            } else {
                Util.toast(data.error, 'error');
            }
        })
    };
    this.getLstTags = function($activeWmsId, $callback) {
    	let _self = this;
        ServiceWtags.instance.pagingWtags({wflow_id: $activeWmsId}).then(({ data }) => {
            if (data.status) {
            	_self.lstTags = data.data
                const tagStage = {
			        ..._self.comStage.state,
			        lstTags: data.data
			    }
			    _self.comStage.setState(tagStage);
			    if (typeof $callback == 'function') {
			    	return $callback(_self.comStage);
			    }
            } else {
                Util.toast(data.error, 'error');
            }
        })
    };
    this.getWconfigs = function() {
    	let _self = this;
        ServiceConfig.instance.wconstants().then(({ data }) => {
        	_self.lstConstants = data;
        	let profiles = Object.assign({}, _self.comStage.state.objData.profiles);
        	let _role = _.find(_self.lstConstants, {identifier: _self.objData.profiles.user_role});
        	if (_role) {
        		_self.objData.profiles.role = _role.name;
        		profiles.role = _role.name;
        	}
            const constantStage = {
		        ..._self.comStage.state,
		        lstConstants: data,
		        objData: {
		            ..._self.comStage.state.objData,
		            profiles: profiles
		        },
		    }
		    _self.comStage.setState(constantStage);
        })
    };
    this.getWidgetpacks = function(overwriteroot = false) {
    	let _self = this;
        ServiceConfig.instance.widgetpacks().then(({ data }) => {
        	_self.lstWidgetpacks = data;
        	let _objData = _self.comStage.state.objData;
        	ServiceWidgets.instance.pagingWidgets({wflow_id:_self.comStage.state.objData.workfolows.id}).then(({ data }) => {
	            let reportTemp = _.find(data.data, { identifier: 'template_report'});
	            if (!reportTemp || reportTemp.status == 'deactive') {
	            	_objData.templateActive = false;
	                _objData.filter.template = 'default';
	                _objData.extendWidgets = [];
	                const _newState = {
				        ..._self.comStage.state,
				        lstWidgetpacks: _self.lstWidgetpacks,
				        objData: {
				            ..._self.comStage.state.objData,
				            templateActive: false,
				            filter: _objData.filter,
				            extendWidgets: [],
				        },
				    }
		    		_self.comStage.setState(_newState);
		    		if (overwriteroot) {
		    			_self.react.setState({wms_class: _self});
		    		}
	                return;
	            }
	            let extendWidgets = [];
	            ServiceWidgets.instance.pagingWidgetExtends({wflow_id:_self.comStage.state.objData.workfolows.id, identifier:'template_report'}).then(({ data }) => {
	                if (data.status) {
	                    extendWidgets = _.orderBy(data.data, ['id'], ['asc']);
	                } else {
	                    Util.toast(data.error, 'error')
	                }
            		_objData.templateActive = true;
	                _objData.filter.template = '';
	                _objData.extendWidgets = extendWidgets;
	                const _newState = {
				        ..._self.comStage.state,
				        lstWidgetpacks: _self.lstWidgetpacks,
				        objData: {
				            ..._self.comStage.state.objData,
				            templateActive: true,
				            filter: _objData.filter,
				            extendWidgets: extendWidgets,
				        },
				    }
		    		_self.comStage.setState(_newState);
		    		if (overwriteroot) {
		    			_self.react.setState({wms_class: _self});
		    		}
	            });
	        });
        })
    };
    this.getLstWinfos = function($callback) {
    	let _self = this;
	    ServiceWinfo.instance.pagingWinfo({}).then(({data}) => {
            if (data.status) {
                let winfo = data.data[0];
                _self.lstWinfos = data.data
                _self.objData.workfolows.id = winfo.id;
	            _self.objData.workfolows.name = winfo.name;
                const winfoStage = {
			        ..._self.comStage.state,
			        lstWinfos: data.data,
			        objData: {
			            ..._self.comStage.state.objData,
			            workfolows: {
			                	id: winfo.id,
						        name: winfo.name
			            }
			        }
			    }
			    // _self.getLstMembers(winfo.id);
			    _self.comStage.setState(winfoStage);
			    return $callback(_self.comStage);
            } else {
                return $callback(false, data.error);
            }
        })
	};
	this.getLstMembers = function($activeWmsId, $callback) {
		let _self = this;
		let filter = Object.assign({}, _self.comStage.state.objData.filter);
	    let objPaging = {
	    	wflow_id: $activeWmsId,
	    	search: filter.inputsearch,
			status: filter.status,
			step: filter.stage_step,
			result: filter.checklist_result,
			assign: filter.assign,
			watches: filter.watches,
			tags: filter.tags,
	    }
        ServiceUser.instance.pagingUser(objPaging).then(({ data }) => {
            if (data.status) {
                _self.lstMembers = _.reject(data.data, function(items) {
                    return !items.isMember;
                });
                const memberStage = {
			        ..._self.comStage.state,
			        lstMembers: data.data,
			        objData: {
			            ..._self.comStage.state.objData,
			            unassign: data.unassign,
			            noticount: Util.renderNumberPlus(data.notify_unread_current_user),
			            notitotal: Util.renderNumberPlus(data.notify_count_current_user),
			        }
			    }
			    _self.comStage.setState(memberStage);
			    return $callback(_self.comStage);
            } else {
                return $callback(false, data.error);
            }
        })
    };
	this.onDragEnd = $result => {
		let _self = this;
	    const {
	        destination,
	        source,
	        draggableId
	    } = $result;
	    if (!destination) {
	        return
	    }
	    if (destination.droppableId === source.droppableId &&
	        destination.index === source.index) {
	        return;
	    }
	    let holdcolumn = _self.comStage.state.objData.columns.map(item => ({...item}));
        const uid = parseInt(draggableId.replace(/task-/g, ''));
	    const start_index = _.findIndex(_self.comStage.state.objData.columns, {step: source.droppableId});
	    const finish_index = _.findIndex(_self.comStage.state.objData.columns, {step: destination.droppableId});
	    const start = _self.comStage.state.objData.columns[start_index];
	    const finish = _self.comStage.state.objData.columns[finish_index];
	    if (start.step === finish.step) {
	        const newTaskIds = Array.from(start.taskIds);
	        newTaskIds.splice(source.index, 1);
	        newTaskIds.splice(destination.index, 0, draggableId);
	        let prev_task = _.find(_self.comStage.state.objData.tasks, {task_id: destination.index > 0 ? newTaskIds[destination.index - 1] : false});
	        _self.comStage.state.objData.columns[start_index]['taskIds'] = newTaskIds;
	        const newState = {
	            ..._self.comStage.state,
	            objData: {
	                ..._self.comStage.state.objData,
	                columns: _self.comStage.state.objData.columns
	            }
	        }
	        _self.comStage.setState(newState);
		    _self.updateTinfo(uid, {prev_uid: prev_task ? prev_task.uid : false}, function(res){
		    	if (!res.status) {
					Util.toast(res.error, 'error');
					const newState = {
				        ..._self.comStage.state,
				        objData: {
				            ..._self.comStage.state.objData,
				            columns: holdcolumn
				        }
				    }
				    _self.comStage.setState(newState);
					return;
				}
		    });
	        return;
	    }
	    let holdtask = _self.comStage.state.objData.tasks.map(item => ({...item}));
	    const startTaskIds = Array.from(start.taskIds);
	    startTaskIds.splice(source.index, 1);
	    _self.comStage.state.objData.columns[start_index]['taskIds'] = startTaskIds;
	    _self.comStage.state.objData.columns[start_index]['offset'] = startTaskIds.length; 
	    _self.comStage.state.objData.columns[start_index]['total'] = _self.comStage.state.objData.columns[start_index]['total'] - 1;
	    if (_self.comStage.state.objData.columns[start_index]['total'] < 0) {
	    	_self.comStage.state.objData.columns[start_index]['total'] = 0;
	    }
	    const finishTaskIds = Array.from(finish.taskIds);
	    finishTaskIds.splice(destination.index, 0, draggableId);
	    let prev_task = _.find(_self.comStage.state.objData.tasks, {task_id: destination.index > 0 ? finishTaskIds[destination.index - 1] : false});
    	let objTask = {step: finish.step, prev_uid: prev_task ? prev_task.uid : false};
	    _self.comStage.state.objData.columns[finish_index]['taskIds'] = finishTaskIds;
	    _self.comStage.state.objData.columns[finish_index]['offset'] = finishTaskIds.length;
	    _self.comStage.state.objData.columns[finish_index]['total'] = _self.comStage.state.objData.columns[finish_index]['total'] + 1;
	    const newState = {
	        ..._self.comStage.state,
	        objData: {
	            ..._self.comStage.state.objData,
	            columns: _self.comStage.state.objData.columns
	        }
	    }
	    _self.comStage.setState(newState);
	    _self.updateTinfo(uid, objTask, function(res){
	    	if (!res.status) {
				Util.toast(res.error, 'error');
				const _newState = {
			        ..._self.comStage.state,
			        objData: {
			            ..._self.comStage.state.objData,
			            columns: holdcolumn,
			            tasks: holdtask
			        }
			    }
			    _self.comStage.setState(_newState);
				return;
			}
		    if (startTaskIds.length > 0) {
		    	let _current = _.find(_self.comStage.state.objData.tasks, {task_id: startTaskIds[0]});
		    	_self.updateTinfo(_current.uid, {prev_uid: false}, function(res){
			    	//
			    });
		    } 
	    });
	};
	this.updateTinfo = function(uid, tinfo, $callback){
		ServiceTinfo.instance.updateTinfo(uid, tinfo).then(({ data }) => {
            return $callback(data);
        })
	};
	this.getLstStages = function($activeWmsId, $callback) {
		let _self = this;
		if (_self.loadingSeq) {
			_self.stopRecurStage = true;
			return $callback(_self.comStage);
		}
		let filter = Object.assign({}, _self.comStage.state.objData.filter);
	    let objPaging = {
	    	wflow_id: $activeWmsId,
	    	search: filter.inputsearch,
			status: filter.status,
			step: filter.stage_step,
			result: filter.checklist_result,
			assign: filter.assign,
			watches: filter.watches,
			tags: filter.tags,
			dateline: filter.dateline,
			date: filter.date,
	    }
		_self.loadingSeq = true;
		const toast_loading = Util.toast(_self.comStage.state.Trans.toast.textLoading, 'loading');
        ServiceSinfo.instance.pagingSinfo(objPaging).then(async ({ data }) => {
            if (data.status) {
            	let wStage;
            	if (filter.stage_step.length > 0) {
					wStage = {
				        ..._self.comStage.state,
				        objData: {
				            ..._self.comStage.state.objData,
				            stages: data.stages,
				            columns: [],
				            tasks: [],
				            inprocess: 0,
				            complete: 0,
				            review: 0,
				            total: 0,
				        }
				    }
            	} else {
					wStage = {
				        ..._self.comStage.state,
				        objData: {
				            ..._self.comStage.state.objData,
				            stages: data.stages,
				            tasks: [],
				            inprocess: 0,
				            complete: 0,
				            review: 0,
				            total: 0,
				        }
				    }

            	}
			    _self.comStage.setState(wStage);
                const stages = data.data;
                if (stages.length > 0) {
	                await _self.recurStages($activeWmsId, stages, 0, async function($arg, $lastest){
	                	if($arg){
	                		_self.loadingSeq = false;
							await Util.toast(_self.comStage.state.Trans.toast.textLoaded, $lastest ? 'update' : 'skip', toast_loading);
							setTimeout(function(){
								return $callback(_self.comStage, $lastest);
							});
	                	}
	                	else {
	                		return $callback(false);
	                	}
	                });
                } else {
                	_self.loadingSeq = false;
            		await Util.toast(_self.comStage.state.Trans.toast.textLoaded, 'update', toast_loading);
            		setTimeout(function(){
						return $callback(_self.comStage);
					});
                }
            } else {
                return $callback(false, data.error);
            }
        })
	};
	this.recurStages =  function($activeWmsId, $lstStage, $onIndex = 0, $callback){
		let _self = this;
		if ($lstStage.length > 0 && $onIndex >= 0){
			$lstStage[$onIndex].spinner = true;
			const $onStage = $lstStage[$onIndex];
			if (_self.stopRecurStage) {
				// _self.stopRecurStage = false;
				return $callback(false);
			}			
			const spinner_Stage = {
		        ..._self.comStage.state,
		        objData: {
		            ..._self.comStage.state.objData,
		            columns: $lstStage
		        }
		    }
		    _self.comStage.setState(spinner_Stage);
			const $request = _self.getLstTasks($activeWmsId, $onStage.step, 10, 0, async function($comStage, $data){
				let objData = Object.assign({}, _self.comStage.state.objData);
	    		if ($comStage) {
	            	$onStage.rows = 10;
	            	$onStage.inprocess = parseInt($data.data[0] ? $data.data[0].inprocess : 0);
	            	$onStage.review = parseInt($data.data[0] ? $data.data[0].review : 0);
	            	$onStage.complete = parseInt($data.data[0] ? $data.data[0].complete : 0);
	            	$onStage.total = parseInt($data.total_rows);
	            	$onStage.taskIds = _.map($data.data, 'task_id');
	            	$onStage.offset = $onStage.taskIds.length;
	            	$onStage.spinner = false;
	            	objData.inprocess += parseInt($onStage.inprocess);
	            	objData.review += parseInt($onStage.review);
	            	objData.complete += parseInt($onStage.complete);
	            	objData.total += parseInt($onStage.total);
	    		} else {
	    			return $callback(false);
	    		}
	    		const wStage = {
			        ..._self.comStage.state,
			        objData: {
			            ..._self.comStage.state.objData,
			            columns: $lstStage,
			            inprocess: objData.inprocess,
			            review: objData.review,
			            complete: objData.complete,
			            total: objData.total,
			        }
			    }
			    await _self.comStage.setState(wStage);
			    _self.comStage.props.wms_class.objData = {..._self.comStage.state.objData}; // change props in header
			    if ($onIndex + 1 < $lstStage.length && !_self.stopRecurStage){
			    	// return setTimeout(async function(){
			    	// 	await _self.recurStages($activeWmsId, $lstStage, $onIndex + 1, $callback);
			    	// }, 500);
			    	// _self.comStage.props.wms_class.objData = {..._self.comStage.state.objData}; // change props in header
			    	_self.onIndexColum = $onIndex;
			    	$callback(_self.comStage, false);
			    	_self.recurStages($activeWmsId, $lstStage, $onIndex + 1, $callback);
				}
				else {
					// _self.comStage.props.wms_class.objData = {..._self.comStage.state.objData}; // change props in header
					// _self.stopRecurStage = false;
					_self.onIndexColum = $onIndex;
					return $callback(_self.comStage, true);
				}
	    	});
		}
		else {
			return $callback(false);
		}
	};
	this.inputsearchLive = function($event) {
        var _self = this;
        var keysByCode = [9, 16, 17, 18, 20, 27, 32, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46, 65, 91, 144];
        if ($event.keyCode >= 112 && $event.keyCode <= 123) return false;
        if (keysByCode.indexOf($event.keyCode) > -1) return false;
        return true;
    };
    this.searchDownTimer = function($coundown, $callback) {
        var _self = this;
        this.myTime = setInterval(function() {
            $coundown--;
            return $callback($coundown);
        }, 100);
    };
    this.searchLiveDownTimer = function($event, $callback) {
    	var _self = this;
        if (!_self.inputsearchLive($event)) {
            return;
        }
        if (_self.myTime != null) clearInterval(_self.myTime);
        _self.searchDownTimer(SEARCH_COUNTDOWN, function(arg) {
            if (arg <= 0) {
                clearInterval(_self.myTime);
                if (typeof $callback == 'function') {
	            	return $callback();
	            } else {
	            	GLOBAL.resetTimer();
                	_self.onSearchTinfos($callback);
	            }
            }
        });
    };
    this.onSearchTinfos = function($callback) {
    	let _self = this;
    	_self.prevent_continuity = true;
    	_self.onsubmit = false;
        _self.trackInprocess();
    	_self.getLstStages(_self.comStage.state.objData.workfolows.id, function($argstg, $lastest){
    	    if($argstg){
                _self.react.setState({wms_class: $argstg});
                _self.stopRecurStage = false;
                if ($lastest && !_self.stopRecurStage){
                    _self.prevent_continuity = false;
                    _self.onsubmit = true;
                    _self.trackInprocess();
                }
                else if (typeof $callback == 'function') {
	            	return $callback();
	            }
            }
            else if (!$lastest || $lastest === undefined) {
        		_self.stopRecurStage = false;
        	}
         });
    };
	this.getLstTasks = async function($activeWmsId, $step, $rows = 10, $offset = 0, $callback) {
		let _self = this;
		let objPaging = {
			wflow_id: $activeWmsId,
			stage_step: $step,
			rows: $rows,
			offset: $offset,
			search: _self.comStage.state.objData.filter.inputsearch,
			status: _self.comStage.state.objData.filter.status,
			result: _self.comStage.state.objData.filter.checklist_result,
			assign: _self.comStage.state.objData.filter.assign,
			watches: _self.comStage.state.objData.filter.watches,
			tags: _self.comStage.state.objData.filter.tags,
			dateline: _self.comStage.state.objData.filter.dateline,
			date: _self.comStage.state.objData.filter.date,
			clear: _self.comStage.state.objData.filter.clear,
		};
        await ServiceTinfo.instance.pagingTinfo(objPaging).then(({ data }) => {
            if (data.status) {
                const tasks = data.data;
                const _tasks = _.map(tasks, function(t){
                	t.on_mains = JSON.parse(t.on_mains);
                	t.on_mains = _.orderBy(t.on_mains, 'fields', 'asc');
                	return t;
                });
                let prev_tasks = _self.comStage.state.objData.tasks;
                let new_tasks = _.uniqBy(_.concat(prev_tasks, _tasks), 'task_id');
                let filter = Object.assign({}, _self.comStage.state.objData.filter);
        		filter.clear = false;
                const wStage = {
			        ..._self.comStage.state,
			        objData: {
			            ..._self.comStage.state.objData,
			            tasks: new_tasks,
			            filter: filter
			        }
			    }
			    _self.comStage.setState(wStage);
			    return $callback(_self.comStage, data);
            } else {
                return $callback(false, data.error);
            }
        }).catch(error => {
        	return $callback(false, error);
        });
	};
	this.getNotifies = async function($callback) {
		let _self = this;
		let objPaging = {
			wflow_id: _self.comStage.state.objData.workfolows.id,
			uid: _self.comStage.state.objData.profiles.uid,
			rows: _self.comStage.state.objData.pagingNoti.rows,
			offset: _self.comStage.state.objData.notifies.length,
			unread: _self.comStage.state.objData.pagingNoti.unread,
			mention: _self.comStage.state.objData.pagingNoti.mention,
		};
		const spinner_Stage = {
	        ..._self.comStage.state,
	        objData: {
	            ..._self.comStage.state.objData,
	            loadingNotifies: true
	        }
	    }
	    _self.comStage.setState(spinner_Stage);
        await ServiceUser.instance.pagingNotifies(objPaging).then(({ data }) => {
            if (data.status) {
                const notifies = data.data;
                let prev_notifies = _self.comStage.state.objData.notifies;
                let new_notifies = _.uniqBy(_.concat(prev_notifies, notifies), 'id');
                const wStage = {
			        ..._self.comStage.state,
			        objData: {
			            ..._self.comStage.state.objData,
			            notifies: new_notifies,
			            notitotal: data.total_rows,
			            noticount: data.notify_unread_current_user,
			            loadingNotifies: false,
			        }
			    }
			    _self.comStage.setState(wStage);
			    if (typeof $callback == 'function') {
	            	return $callback(_self.comStage, data);
	            }
            } else {
                if (typeof $callback == 'function') {
	            	return $callback(false, data.error);
	            }
            }
        }).catch(error => {
        	if (typeof $callback == 'function') {
            	return $callback(false, error);
            }
        });
	};
	this.exportExcel = async function() {
		let _self = this;
		let objPaging = {
			wflow_id: _self.comStage.state.objData.workfolows.id,
			stage_step: _self.comStage.state.objData.filter.stage_step,
			search: _self.comStage.state.objData.filter.inputsearch,
			status: _self.comStage.state.objData.filter.status,
			result: _self.comStage.state.objData.filter.checklist_result,
			assign: _self.comStage.state.objData.filter.assign,
			watches: _self.comStage.state.objData.filter.watches,
			tags: _self.comStage.state.objData.filter.tags,
			dateline: _self.comStage.state.objData.filter.dateline,
			date: _self.comStage.state.objData.filter.date,
			template: _self.comStage.state.objData.filter.template,
		};
		if (objPaging.template == '') {
            Util.toast(_self.comStage.state.Trans.toast.required_export_temp, 'error');
            return;
        }
		if (!_self.onsubmit) {
            Util.toast(_self.comStage.state.Trans.toast.isLoading, 'warning');
            return;
        }
        _self.onsubmit = false;
        const toast_loading = Util.toast(_self.comStage.state.Trans.toast.textLoading, 'loading');
        await ServiceTinfo.instance.exportExcel(objPaging).then(({ data }) => {
            if (data.status) {
            	let path = Util.getAPIPrefix() + '/downloadFileStorage/' + data.uuid;
                Util.saveFileUnderPopup(path, data.filename, data.token, data.sessionid);
            } else {
                Util.toast(data.error, 'error');
            }
        	Util.toast(_self.comStage.state.Trans.toast.textLoaded, 'update', toast_loading);
            _self.onsubmit = true;
        }).catch(error => {
        	Util.toast(_self.comStage.state.Trans.toast.textLoaded, 'update', toast_loading);
        	_self.onsubmit = true;
        });
	};
	this.trackInprocess = function() {
		let _self = this;
		if (_self.prevent_continuity) {
            return;
        }
		if (_self.onsubmit) {
            if (Boolean(GLOBAL.check_on_submit)) {
                Util.toast(_self.comStage.state.Trans.toast.request_done, "success");
                GLOBAL.resetTimer();
            }
            _self.prevent_continuity = false;
            _self.comStage.setState({prevent_continuity: false});
        } else if (!_self.delayloading) {
            GLOBAL.inprogress_counter = 0;
            if (Boolean(GLOBAL.check_on_submit)) {
                GLOBAL.resetTimer();
            }
            GLOBAL.check_on_submit = setInterval(function() {
                GLOBAL.inprogress_counter++;
                if (GLOBAL.inprogress_counter == 1) {
                    GLOBAL.check_clicked = true;
                } else if (GLOBAL.inprogress_counter == 20) {
                    Util.toast(_self.comStage.state.Trans.toast.slow_connection, "warning");
                    setTimeout(function() {
                        var r = window.confirm(_self.comStage.state.Trans.toast.reload);
                        if (r == true) {
                            window.location.reload();
                        } else {
                            Util.toast(_self.comStage.state.Trans.toast.isLoading, "warning");
                        }
                    }, 20000);
                } else if (GLOBAL.inprogress_counter == 40) {
                    _self.onsubmit = true;
                    _self.comStage.setState({onsubmit: false});
                    _self.trackInprocess();
                }
            }, 1250);
        }
	};
	this.filterWmembers = (filter = {}, $callback) => {
    	ServiceUser.instance.pagingWmember(filter).then(({ data }) => {
    		if (typeof $callback == 'function') {
		    	return $callback(data.data);
		    }
	    })
    };
    this.searchWmembers = function($event, filter = {}, $callback) {
    	var _self = this;
        if (!_self.inputsearchLive($event)) {
            return;
        }
        if (_self.myTime != null) clearInterval(_self.myTime);
        _self.searchDownTimer(SEARCH_COUNTDOWN, function(arg) {
            if (arg <= 0) {
                clearInterval(_self.myTime);
                _self.filterWmembers(filter, $callback);
            }
        });
    };
    this.getThumbnail = function(props, pathfile, elementid) { 
        const youTubeIdFromLink = (url) => url.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/);
        const getMeta = async (url, _props, _elementid, _iteminfo) => {
          let img = new Image();
          img.src = url;
            try {
                await img.decode();
            }
            catch(err) {
                img = null;
            }
          return img
        };
        const asyncData = async (_props, _elementid, _iteminfo, _index_task_stage) => {
        	await setTimeout(async function(){
            	if (_props.Tinfo && _props.Tinfo.attachments[_elementid]){
	                await _props.setTinfo(prevTinfo => ({...{}, ..._iteminfo}));
	            }
	            if (_props.tasks && _props.tasks[_elementid]) {
	            	let _findIndex = _.findLastIndex(_iteminfo.tasks, function(o) { return o.minetype == 'other'; });
		            if (_props.tasks && _props.tasks[_elementid] && ((_index_task_stage + 1 == _props.react.state.objData.tasks.length && _props.react.workflowman.onIndexColum < _props.react.state.objData.columns.length - 1) //
		            	|| (_findIndex == _index_task_stage && _props.react.workflowman.onIndexColum == _props.react.state.objData.columns.length - 1))) {
					    await _props.react.setState({
					        objData: _iteminfo
					    });
					}
				}
            });
        };
        let fId = youTubeIdFromLink(pathfile);
        let _iteminfo = null;
    	let index_task_stage = null;
        if (props.Tinfo && props.Tinfo.attachments[elementid] && props.Tinfo.attachments[elementid].minetype){
            return;
        }
        if (props.tasks && props.tasks[elementid] && props.tasks[elementid].minetype){
            return;
        }
        if (props.Tinfo && props.Tinfo.attachments[elementid]){
            _iteminfo = {...props.Tinfo};
        }
        if (props.tasks && props.tasks[elementid]){
            _iteminfo = props.react.state.objData;
            index_task_stage = _.findIndex(_iteminfo.tasks, {
            	uid: props.tasks[elementid].uid
            });
        }
        if(fId){
            if (_iteminfo){
            	_.extend(_iteminfo.attachments ? _iteminfo.attachments[elementid] : //
	                    	index_task_stage != -1 ? _iteminfo.tasks[index_task_stage] : {}, {
	                minetype: "youtube",
	                thumbnail: "//img.youtube.com/vi/" + fId[1] + "/0.jpg"
	            })
	            asyncData(props, elementid, _iteminfo, index_task_stage);
            }
        }
        else {
        	if (_iteminfo && pathfile){
        		var image = new Image();
			    image.onload = function()
			    {
			        _.extend(_iteminfo.attachments ? _iteminfo.attachments[elementid] : //
	                	index_task_stage != -1 ? _iteminfo.tasks[index_task_stage] : {}, {
	                    minetype: "image",
	                    thumbnail: pathfile
	                });
	                asyncData(props, elementid, _iteminfo, index_task_stage);
			    }
			    image.onerror = function()
			    {
			        _.extend(_iteminfo.attachments ? _iteminfo.attachments[elementid] : //
	                	index_task_stage != -1 ? _iteminfo.tasks[index_task_stage] : {}, {
	                    minetype: "other",
	                    thumbnail: "../../media/img/folder_movie-link.png"
	                });
	                asyncData(props, elementid, _iteminfo, index_task_stage);
			    }
			    image.src = pathfile;
			}
            // getMeta(pathfile, props, elementid, _iteminfo).then(img => {
	        //     if (_iteminfo){
	        //     	if (img){
	        //             _.extend(_iteminfo.attachments ? _iteminfo.attachments[elementid] : //
	        //             	index_task_stage != -1 ? _iteminfo.tasks[index_task_stage] : {}, {
	        //                 minetype: "image",
	        //                 thumbnail: pathfile
	        //             });
	        //         }
	        //         else {
	        //             _.extend(_iteminfo.attachments ? _iteminfo.attachments[elementid] : //
	        //             	index_task_stage != -1 ? _iteminfo.tasks[index_task_stage] : {}, {
	        //                 minetype: "other",
	        //                 thumbnail: "../../media/img/folder_movie-link.png"
	        //             });
	        //         }
	        //         asyncData(props, elementid, _iteminfo, index_task_stage);
	        //     }
            // });
        }
    };
    this.handleLogout = async function() {
    	var _self = this;
        ServiceAuth.instance.logout()
        .then(async ({ data }) => {
        	await Util.clearLocalData();
            _self.comStage.setState({isLogin: false});
		    _self.react.setState({wms_class: _self.comStage});
        })
        .catch(async () => {
        	await Util.clearLocalData();
            _self.comStage.setState({isLogin: false});
		    _self.react.setState({wms_class: _self.comStage});
        });
	};
    this.handleNotifyEvent = async function($notifydata) {
    	var _self = this;
		let _objData = {..._self.comStage.state.objData};
    	switch($notifydata['key_type']){
	    	case 'updateWinfo':
	            _objData.workfolows.name = $notifydata['notify']['name'];
			    await _self.comStage.setState({objData: _objData});
	    		await _self.react.setState({wms_class: _self.comStage});
			    publish('showWinfo');
	    		break;
	    	case 'pagingWtags':
			    publish('pagingWtags');
	    		break;
	    	case 'pagingSinfo':
			    publish('pagingSinfo');
	    		break;
	    	case 'updateTinfo':
			    // update main task in stage
	            let _index = _.findIndex(_objData.tasks, {
	            	uid: $notifydata['notify']['uid']
	            });
	            if (_objData.tasks[_index]) {
		    		publish('showTinfo', {token: $notifydata['token'], type_update: $notifydata['type_update'], haslog: $notifydata['haslog'], tinfo: $notifydata['notify']});
	            	let _tinfo = $notifydata['notify'];
	            	let _result_bf = _objData.tasks[_index]['result'];
	            	_objData.tasks[_index]['name'] = _tinfo['name'];
	            	_objData.tasks[_index]['fname_client'] = _tinfo['content'];
	            	_objData.tasks[_index]['described'] = _tinfo['described'];
	            	_objData.tasks[_index]['assign'] = _tinfo['assign'];
	            	_objData.tasks[_index]['index'] = _tinfo['index'];
	            	_objData.tasks[_index]['result'] = _tinfo['result'];
	            	_objData.tasks[_index]['type'] = _tinfo['type'];
	            	if (_objData.tasks[_index]['pathfile'] != _tinfo['pathfile']) {
	            		delete _objData.tasks[_index]['minetype'];
	            		_objData.tasks[_index]['pathfile'] = _tinfo['pathfile'];
	            	}
	            	_objData.tasks[_index]['duedate'] = _tinfo['duedate'];
	            	_objData.tasks[_index]['overdue'] = _tinfo['overdue'];
	            	_objData.tasks[_index]['indue'] = _tinfo['indue'];
	            	_objData.tasks[_index]['countmsg'] = _tinfo['countmsg'];
	            	_objData.tasks[_index]['checklist'] = _tinfo['checklist'];
	            	_objData.tasks[_index]['tags'] = _tinfo['tagids'];
	            	_objData.tasks[_index]['attach'] = _tinfo['attach'];
	            	_objData.tasks[_index]['on_mains'] = _tinfo['on_mains'];
	            	_objData.tasks[_index]['extend_date'] = _tinfo['extend_date'];
	            	_objData.tasks[_index]['extend_num'] = _tinfo['extend_num'];
	        		let _indexStage = _.findIndex(_objData.columns, {
		            	step: _tinfo['step']
		            });
	        		let _oldIndexTask = _objData.columns[_indexStage] ? _objData.columns[_indexStage]['taskIds'].indexOf(_tinfo['task_id']) : -1;
	        		let _newIndexTask = _tinfo['index'] - 1;
	            	if (_objData.tasks[_index]['step'] == _tinfo['step'] && _oldIndexTask != _newIndexTask && _newIndexTask < _objData.columns[_indexStage]['taskIds'].length) {
	            		const newTaskIds = Array.from(_objData.columns[_indexStage]['taskIds']);
	            		if (_oldIndexTask > -1) {
				        	newTaskIds.splice(_oldIndexTask, 1);
				        	_objData.columns[_indexStage]['offset'] = newTaskIds.length;
	            		}
				        newTaskIds.splice(_newIndexTask, 0, _tinfo['task_id']);
				        _objData.columns[_indexStage]['taskIds'] = newTaskIds;
	            	}
	            	if (_objData.tasks[_index]['step'] != _tinfo['step']) {
	            		const start_index = _.findIndex(_objData.columns, {step: _objData.tasks[_index]['step']});
					    const finish_index = _.findIndex(_objData.columns, {step: _tinfo['step']});
					    const start = _objData.columns[start_index];
					    const finish = _objData.columns[finish_index];
						const startTaskIds = Array.from(start.taskIds);
						const __index = startTaskIds.indexOf(_tinfo['task_id']);
						if (__index > -1) {
					    	startTaskIds.splice(__index, 1);
					    	_objData.columns[start_index]['offset'] = startTaskIds.length;
					    	_objData.columns[start_index]['total'] = _objData.columns[start_index]['total'] - 1;
						}
						if (_objData.columns[start_index]['total'] < 0) {
							_objData.columns[start_index]['total'] = 0;
						}
				    	_objData.columns[start_index][_result_bf] = _objData.columns[start_index][_result_bf] - 1;
				    	if (_objData.columns[start_index][_result_bf] < 0) {
				    		_objData.columns[start_index][_result_bf] = 0;
				    	}
					    _objData.columns[start_index]['taskIds'] = startTaskIds;
					    const finishTaskIds = Array.from(finish.taskIds);
					    if (finishTaskIds.indexOf(_tinfo['task_id']) < 0) {
					    	finishTaskIds.splice(_tinfo['index'] - 1, 0, _tinfo['task_id']);
					    	_objData.columns[finish_index]['offset'] = finishTaskIds.length;
				    		_objData.columns[finish_index]['total'] = _objData.columns[finish_index]['total'] + 1;
					    }
			    		_objData.columns[finish_index][_tinfo['result']] = _objData.columns[finish_index][_tinfo['result']] + 1;
				    	_objData.columns[finish_index]['taskIds'] = finishTaskIds;
	            		_objData.tasks[_index]['step'] = _tinfo['step'];
	            	}
	            	if (_objData.tasks[_index]['status'] != _tinfo['status'] && _tinfo['status'] != _objData.filter.status) {
					    const finish_index = _.findIndex(_objData.columns, {step: _tinfo['step']});
					    const finish = _objData.columns[finish_index];
						const finishTaskIds = Array.from(finish.taskIds);
						const __index = finishTaskIds.indexOf(_tinfo['task_id']);
						if (__index > -1) {
					    	finishTaskIds.splice(__index, 1);
					    	_objData.columns[finish_index]['offset'] = finishTaskIds.length;
						}
						_objData.tasks.splice(_index, 1);
	            	}
	            	if ($notifydata['type_update'] == 'update_tinfo_task_time' && _tinfo.tracking[0].realtime == _self.comStage.state.objData.profiles.uid) {
	            		let onTracking = Util.calculateTime(_tinfo.tracking);
			            _objData.isRunning = onTracking[0];
	            		_objData.tasktime = onTracking[1];
			            _objData.task_id = onTracking[0] ? _tinfo.tracking[0].task_id : -1;
	            	}
			        await _self.comStage.setState({objData: _objData});
	    			await _self.react.setState({wms_class: _self.comStage});
	            }
	            _self.getLstMembers(_self.comStage.state.objData.workfolows.id, function(arg, status){
		    		if (arg) {
		    			_self.react.setState({wms_class: arg});
		    		} else {
		    			//
		    		}
		    	});
	    		break;
	    	case 'pagingUser':
	    		let _member = $notifydata['notify'];
			    _self.getLstMembers(_self.comStage.state.objData.workfolows.id, function(arg, status){
		    		if (arg) {
		    			if (_member != 'pagingUser' && _member['uid'] && _member['uid'] == _self.comStage.state.objData.profiles.uid) {
		    				let _findIndex = _.findIndex(_self.comStage.state.lstMembers, {uid: _member['uid']});
		    				if (_findIndex < 0 || !_self.comStage.state.lstMembers[_findIndex]['isMember']) {
		    					return _self.handleLogout();
		    				}
				            _objData.profiles.fullname = _member['fullname'];
				            _objData.profiles.email = _member['username'];
				            _objData.profiles.avatar = _member['avatar'];
				            _objData.profiles.user_role = _member['user_role'];
				            let _role = _.find(_self.comStage.state.lstConstants, {identifier: _objData.profiles.user_role});
				        	if (_role) {
				        		_self.objData.profiles.role = _role.name;
				        		_objData.profiles.role = _role.name;
				        	}
				            _objData.profiles.accessfield = _member['accessfield'];
				            _objData.profiles = _objData.profiles;
				            localStorage.setItem(PREFIX_LOCAL + 'username', JSON.stringify(_member['username']));
				            const profileStage = {
						        ..._self.comStage.state,
						        objData: {
						            ..._self.comStage.state.objData,
						            profiles: _objData.profiles
						        },
						    }
						    _self.comStage.setState(profileStage);
		    			}
		    			_self.react.setState({wms_class: arg});
		    		} else {
		    			//
		    		}
		    	});
	    		break;
	    	case 'storeTinfo':
			    // update main task in stage
            	let _tinfo = $notifydata['notify'];
			    if (_tinfo['status'] == _objData.filter.status) {
	            	let __tinfo = _tinfo;
	            	__tinfo['fname_client'] = _tinfo['content'];
	            	__tinfo['tags'] = _tinfo['tagids'];
	            	_objData.tasks.splice(_objData.tasks.length, 0, __tinfo);
	        		let index_stage = _.findIndex(_objData.columns, {
		            	step: _tinfo['step']
		            });
		            let finish = _objData.columns[index_stage];
				    let finishTaskIds = Array.from(finish.taskIds);
				    finishTaskIds.splice(_tinfo['index'] - 1, 0, _tinfo['task_id']);
			    	_objData.columns[index_stage]['offset'] = finishTaskIds.length;
			    	if (_objData.columns[index_stage][_tinfo['result']] != undefined) {
		    			_objData.columns[index_stage][_tinfo['result']] = _objData.columns[index_stage][_tinfo['result']] + 1;
		    		}
		    		_objData.columns[index_stage]['total'] = _objData.columns[index_stage]['total'] + 1;
			    	_objData.columns[index_stage]['taskIds'] = finishTaskIds;
			    	if (_objData[_tinfo['result']] != undefined) {
			    		_objData[_tinfo['result']] = _objData[_tinfo['result']] + 1;
			    	}
			    	_objData['total'] = _objData['total'] + 1;
			        await _self.comStage.setState({objData: _objData});
	    			await _self.react.setState({wms_class: _self.comStage});
	            }
	            _self.getLstMembers(_self.comStage.state.objData.workfolows.id, function(arg, status){
		    		if (arg) {
		    			_self.react.setState({wms_class: arg});
		    		} else {
		    			//
		    		}
		    	});
	    		break;
	    	case 'deleteTinfo':
			    // update main task in stage
			    let _index_task = _.findIndex(_objData.tasks, {
	            	uid: $notifydata['notify']['uid']
	            });
	            if (_objData.tasks[_index_task]) {
	            	const start_index = _.findIndex(_objData.columns, {step: _objData.tasks[_index_task]['step']});
				    const start = _objData.columns[start_index];
					const startTaskIds = Array.from(start.taskIds);
					const __index = startTaskIds.indexOf($notifydata['notify']['task_id']);
					if (__index > -1) {
				    	_objData.tasks.splice(_index_task, 1);
				    	startTaskIds.splice(__index, 1);
				    	_objData.columns[start_index]['offset'] = startTaskIds.length;
				    	if (_objData.columns[start_index][$notifydata['notify']['result']]) {
				    		_objData.columns[start_index][$notifydata['notify']['result']] = _objData.columns[start_index][$notifydata['notify']['result']] - 1;
				    	}
				    	_objData.columns[start_index]['total'] = _objData.columns[start_index]['total'] - 1;
				    	_objData.columns[start_index]['taskIds'] = startTaskIds;
				    	if (_objData[$notifydata['notify']['result']] != undefined) {
				    		_objData[$notifydata['notify']['result']] = _objData[$notifydata['notify']['result']] + 1;
				    	}
				    	_objData['total'] = _objData['total'] - 1;
				    	await _self.comStage.setState({objData: _objData});
	    				await _self.react.setState({wms_class: _self.comStage});
			            _self.getLstMembers(_self.comStage.state.objData.workfolows.id, function(arg, status){
				    		if (arg) {
				    			_self.react.setState({wms_class: arg});
				    		} else {
				    			//
				    		}
				    	});
					}
				}
	    		break;
	    	case 'updateNotifies':
	    		if ($notifydata['notify'] && $notifydata['notify']['member_uid'] == _self.comStage.state.objData.profiles.uid) {
	    			let noticount = _objData.noticount;
	    			let notitotal = _objData.notitotal;
	    			let prev_notifies = _objData.notifies;
	    			let _wStage;
	    			switch($notifydata['type_update']){
	    				case 'store_notify':
			    			if (prev_notifies.length > 0) {
				                let new_notifies = _.uniqBy([$notifydata['notify']].concat(prev_notifies), 'id');
				                _wStage = {
							        ..._self.comStage.state,
							        objData: {
							            ..._self.comStage.state.objData,
							            notifies: new_notifies,
							            noticount: noticount.length > 2 ? noticount : Util.renderNumberPlus(parseInt(noticount) + 1),
							            notitotal: parseInt(notitotal) + 1,
							        }
							    }
			    			} else {
			    				_wStage = {
							        ..._self.comStage.state,
							        objData: {
							            ..._self.comStage.state.objData,
							            noticount: noticount.length > 2 ? noticount : Util.renderNumberPlus(parseInt(noticount) + 1),
							            notitotal: parseInt(notitotal) + 1,
							        }
							    }
			    			}
			    			await _self.comStage.setState(_wStage);
		    				await _self.react.setState({wms_class: _self.comStage});
	    				break;
	    				case 'update_notify':
	    				case 'delete_notify':
	    					let headers = Util.getHeaders();
	    					let __index = _.findIndex(prev_notifies, {
				            	id: $notifydata['notify']['id']
				            });
			    			if (prev_notifies[__index] && $notifydata['token'] !== headers.headers.Authorization) {
			    				let _noticount = prev_notifies[__index]['isread'] === 'yes' && $notifydata['notify']['isread'] === 'no' ? (noticount.length > 2 ? noticount : Util.renderNumberPlus(parseInt(noticount) - 1)) : noticount;
			    				if ($notifydata['type_update'] == 'delete_notify') {
			    					prev_notifies.splice(__index, 1);
					                _wStage = {
								        ..._self.comStage.state,
								        objData: {
								            ..._self.comStage.state.objData,
								            notifies: prev_notifies,
								            noticount: _noticount,
								            notitotal: parseInt(notitotal) - 1,
								        }
								    }
			    				} else {
			    					prev_notifies[__index]['isread'] = $notifydata['notify']['isread'];
			    					prev_notifies[__index]['bookmark'] = $notifydata['notify']['bookmark'];
			    					_wStage = {
								        ..._self.comStage.state,
								        objData: {
								            ..._self.comStage.state.objData,
								            notifies: prev_notifies,
								            noticount: _noticount,
								        }
								    }
			    				}
			    			}
			    			await _self.comStage.setState(_wStage);
		    				await _self.react.setState({wms_class: _self.comStage});
	    				break;
	    			}
	    		}
	    		break;
	    	default:
	    		break;
    	}
    };
    this.unlockOnSubmit = function() {
    	let _self = this;
    	$(document).on('click', function(e) {
		    if (Boolean(GLOBAL.check_on_submit) && GLOBAL.check_clicked) {
	        	Util.toast(_self.comStage.state.Trans.toast.unlockOnSubmit, "info");
		        setTimeout(function() {
		            GLOBAL.check_on_submit = true;
		        }, 4000);
		    }
		});
	};
}