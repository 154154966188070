// import npm packge
import React from "react";
import { connect } from "react-redux";
import $ from 'jquery';
import { InputGroup, Input, Button, ListGroup, ListGroupItem} from 'reactstrap';
// import service api
//
// import core class
import Util from '../../core/util';
import { HELP_URL } from '../../core/constant';
// import com page
import {SProfile, SWorkflow} from './../Widget/wmsSettings';
import {AccountProfile} from './../Modal/accountModal';
import {WorkflowSetting} from './../Modal/WorkflowSetting';
import {TrackingTime} from './../Stage/Task';
// import modal tool
import {PopoverNotifies} from "./../Modal/popoverModal";
// import style media
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RxDividerVertical } from "react-icons/rx";
import { FaFileDownload, FaHireAHelper, FaRegBell, FaPlusCircle } from "react-icons/fa";
import { FaRegCircleStop } from "react-icons/fa6";
import { MdFilterAlt } from "react-icons/md";
import { GoSidebarExpand } from "react-icons/go";
import { BsArrowBarRight, BsArrowBarLeft } from "react-icons/bs";
import "./default.css";
// coding
export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputsearch: '',
			onLoading: false,
			isOpenNotifies: false,
		};
		this.openNotifies = this.openNotifies.bind(this);
	}
	componentDidMount() {
		if(this.props.on_header){
			$(".menu-toggle").click(function (e) {
			    e.preventDefault();
			    $("#wrapper").toggleClass("toggled");
			});
			$("#menu-toggle-close").trigger('click');
		}
	};
	componentDidUpdate(prevProps, prevState) {
        if (this.props.wms_class && this.props.wms_class.comStage && this.props.wms_class.comStage.state && this.props.wms_class.comStage.state.objData && this.props.wms_class.comStage.state.objData.filter.inputsearch != this.state.inputsearch) {
        	this.setState({inputsearch: this.props.wms_class.comStage.state.objData.filter.inputsearch});
        }
    };
	handleChange(e) {
		let {name, value} = e.target;
		this.setState({[name]: value});
		let filter = {...this.props.wms_class.comStage.state.objData.filter};
		filter.inputsearch = value;
		const wStage = {
	        ...this.props.wms_class.comStage.state,
	        objData: {
	            ...this.props.wms_class.comStage.state.objData,
	            filter: filter
	        }
	    }
		this.props.wms_class.comStage.setState(wStage);
		this.props.wms_class.react.setState(this.props.wms_class);
		this.props.wms_class.searchLiveDownTimer(e);
	}
	openNotifies = async(click) => {
		let __this = this;
		let wms_class = __this.props.wms_class;
		if (!wms_class.onsubmit) {
			if (click) {
            	Util.toast(wms_class.comStage.state.Trans.toast.isLoading, 'warning');
			}
            return;
        }
		if (!__this.state.isOpenNotifies) {
			__this.setState({isOpenNotifies: !__this.state.isOpenNotifies});
			await wms_class.getNotifies(async function(arg, data) {
				await __this.props.wms_class.react.setState(arg);
			});
		} else {
			__this.setState({isOpenNotifies: !__this.state.isOpenNotifies, initNotifies: false});
			let pagingNoti = Object.assign({}, wms_class.comStage.state.objData.pagingNoti);
        	pagingNoti.unread = false;
        	pagingNoti.mention = false;
			const wStage = {
		        ...wms_class.comStage.state,
		        objData: {
		            ...wms_class.comStage.state.objData,
		            notifies: [],
		            pagingNoti: pagingNoti,
		        }
		    }
		    wms_class.comStage.setState(wStage);
		    await __this.props.wms_class.react.setState({wms_class: wms_class.comStage});
		}
	}
	render() {
		let _self = this.props.wms_class;
	    return (
	      	this.props.on_header ? <nav className="navbar navbar-default">
		      	<div className="container-fluid flex-nowrap">
		      		<div className="navbar-header d-flex align-items-center">
		      			<a href="#" className="btn btn-light hover-effect-border d-flex align-self-center menu-toggle text-black-50" id="menu-toggle-open" title={`Open Menu`}>
							<BsArrowBarRight size="21" className={``}/>
						</a>
						<a href="#" className="btn btn-light hover-effect-border d-flex align-self-center menu-toggle text-black-50" id="menu-toggle-close" title={`Close Menu`}>
							<BsArrowBarLeft size="21" className={``}/>
						</a>
						<div className="none-768 btn disabled btn-light d-flex align-self-center bg-transparent border-0"><RxDividerVertical className="text-black-50" /></div>
			          	<SWorkflow wms_class={this.props.wms_class} react={this.props.react} header_self={this}/>
                		<WorkflowSetting wms_class={this.props.wms_class} react={this.props.react} header_self={this}/>
			        </div>
			        <div className="d-flex">
			        	<span className="none-768">&nbsp;</span>
			        	<button type="button" className="none-768 btn btn-light hover-effect-border d-flex align-self-center py-2" title={_self && _self.comStage && _self.comStage.state ? _self.comStage.state.Trans.header.new_task : 'New Task'} onClick={() => this.props.react.toggleModalAddTask() }><FaPlusCircle  /></button>
			        	<span>&nbsp;</span>
						{_self && _self.comStage && _self.comStage.state && _self.comStage.state.objData && _self.comStage.state.objData.task_id > 0 && (
							<TrackingTime module={'root'} task_id={_self.comStage.state.objData.task_id} task_time={_self.comStage.state.objData.tasktime} isRunning={_self.comStage.state.objData.isRunning} wms_class={_self} />
						)}
						<div className="btn disabled btn-light fw-bold d-flex align-self-center py-1"><span className="badge bg-light text-dark my-1 px-0 d-flex align-self-center">({Util.renderNumberPlus(_self.comStage ? _self.comStage.state.objData.inprocess : 0)}/{Util.renderNumberPlus(_self.comStage ? _self.comStage.state.objData.review : 0)}/{Util.renderNumberPlus(_self.comStage ? _self.comStage.state.objData.complete : 0)}/{Util.renderNumberPlus(_self.comStage ? _self.comStage.state.objData.total : 0)})</span><label className={`none-480 ms-1`}>{_self && _self.comStage && _self.comStage.state ? _self.comStage.state.Trans.header.tasks : 'Tasks'}</label></div>
						<span className="none-480">&nbsp;</span>
						<InputGroup className={`w-auto d-flex align-self-center flex-nowrap none-480`} title={_self && _self.comStage && _self.comStage.state ? _self.comStage.state.Trans.header.inputsearch : 'Search'}>
							<Input name="inputsearch" value={this.state.inputsearch} onChange={(e) => this.handleChange(e)} placeholder={_self && _self.comStage && _self.comStage.state ? _self.comStage.state.Trans.header.inputsearch : 'Search'} className={`border-light`} style={{width: `var(--pix-w-210)`}}/>
							<Button className={`btn-light hover-effect-border disabled`}>
							  	<FontAwesomeIcon icon={faMagnifyingGlass} />
							</Button>
						</InputGroup>
						<span>&nbsp;</span>
						<Button onClick={() => _self.renderWidth(_self.rightFilterShowing)} id="wms-advance-filter" color="light" className="hover-effect-border d-flex align-self-center" title={_self && _self.comStage && _self.comStage.state ? _self.comStage.state.Trans.header.filter : 'Filter'}>
							<MdFilterAlt size="21" />
						</Button>
						<span>&nbsp;</span>
						<Button id="popover-notifies" color='light' className="hover-effect-border d-flex align-self-center btn btn-light position-relative py-2" title={_self && _self.comStage && _self.comStage.state ? _self.comStage.state.Trans.header.notifications : 'Notifications'} onClick={() => this.openNotifies(true)}>
							<FaRegBell />
					  		<span style={{width: `24px`, height: `24px`, fontSize: `var(--fs-9)`}} className="position-absolute translate-middle p-1 bg-danger border border-light rounded-circle text-white top-0 start-100">{_self && _self.comStage && _self.comStage.state ? _self.comStage.state.objData.noticount : 0}</span>
						</Button>
						<div className="none-768 btn disabled btn-light d-flex align-self-center bg-transparent border-0 pe-0"><RxDividerVertical className="text-black-50" /></div>
						<SProfile wms_class={this.props.wms_class} react={this.props.react} header_self={this}/>
			          	<AccountProfile wms_class={this.props.wms_class} react={this.props.react} header_self={this}/>
			          	<PopoverNotifies 
	                        wms_class={this.props.wms_class} react={this.props.react} header_self={this}
	                    />
			        </div>
		      	</div>
	      	</nav> : <ListGroup className={`negative-none-768 position-fixed bottom-0 p-3`} style={{zIndex: 10}} horizontal>
	      				<button type="button" className="btn btn-primary d-flex align-self-center py-2 border-0" title={_self && _self.comStage && _self.comStage.state ? _self.comStage.state.Trans.header.new_task : 'New Task'} onClick={() => this.props.react.toggleModalAddTask() }><FaPlusCircle /></button>
	      			</ListGroup>
    	);
	}
}