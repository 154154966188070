// import npm packge
import React from "react";
import * as moment from "moment";
import _ from 'lodash';
import 'moment/locale/vi';
import 'moment/locale/en-gb';
import SVG from 'react-inlinesvg';
import { UncontrolledPopover, Popover, PopoverHeader, PopoverBody, ListGroup, InputGroup, CardBody, CardTitle, CardText, CardLink, Spinner} from 'reactstrap';
import CoolTabs from 'react-cool-tabs';
import { Scrollbars } from 'react-custom-scrollbars-2';
// import service api
import ServiceUser from '../../services/ServiceUser';
// import core class
import Util from '../../core/util';
import { WIDTH_COOLTAB, HEIGHT_COOLTAB } from '../../core/constant';
// import com page
//
// import modal tool
//
// import icon
import { MdOutlineAlternateEmail, MdMarkUnreadChatAlt, MdBookmarkAdded, MdDelete, MdOutlineCheck, MdAddTask, //
    MdOutlineComment, MdAttachFile, MdOutlineDeleteSweep  } from "react-icons/md";
import { SlLike } from "react-icons/sl";
// import style media
import "./default.css";
// coding
function PopoverStyle($this) {
    return (
        <UncontrolledPopover
			placement="bottom"
			target={$this.target}
			trigger="legacy"
			delay={{show: 150, hide: 0 }}
			animation="true"
			className="stage-popover"
		>
		    <PopoverHeader className="bg-transparent">
		      	{$this.header ? $this.header : "Preferences"}
		    </PopoverHeader>
		    <PopoverBody className="p-2">
				<div style={{ overflow: "hidden", width: "100%" }}>
					<CoolTabs
						tabKey={'1'}
						className="mt-1"
						style={{ maxHeight: 'calc(100vh - 175px)', width: $this.width ? $this.width : WIDTH_COOLTAB, height: $this.height ? $this.height : HEIGHT_COOLTAB}}
						tabsHeaderClassName=""
						tabsHeaderStyle={{}}
						activeTabClassName="active"
						activeTabStyle={{}}
						unActiveClassName=""
						unActiveTabStyle={{}}
						leftTabClassName="btn btn-light hover-effect"
						leftTabStyle={{}}
						rightTabClassName="btn btn-light hover-effect"
						rightTabStyle={{}}
						leftTabTitleClassName="text-secondary fw-bolder"
						leftTabTitleStyle={{}}
						rightTabTitleClassName="text-secondary fw-bolder"
						rightTabTitleStyle={{}}
						tabsBorderBottomClassName="bg-light"
						tabsBorderBottomStyle={{ height: 1 }}
						contentContainerClassName="stg-content"
						contentContainerStyle={{height: '80%'}}
						activeLeftTabBorderBottomClassName="active"
						activeLeftTabBorderBottomStyle={{ height: 1 }}
						activeRightTabBorderBottomClassName="active"
						activeRightTabBorderBottomStyle={{ height: 1 }}
						leftContentClassName="stg-lcontent"
						leftContentStyle={{}}
						rightContentClassName="stg-rcontent"
						rightContentStyle={{}}
						leftTabTitle={'Select Style'}
						rightTabTitle={'More Icons'}
						leftContent={$this.lcontent ? $this.lcontent : "Never content"}
						rightContent={$this.rcontent ? $this.rcontent : "Never content"}
						contentTransitionStyle={'transform 0.2s ease-in'}
						borderTransitionStyle={'all 0.2s ease-in'} />
				</div>
		    </PopoverBody>
	  	</UncontrolledPopover>
    )
}
function PopoverNotifies($this) {
    let objConfig = Util.getCalendarConfig($this.wms_class.objData.profiles.language);
    const bookmarkNotify = async(e, i) => {
        e.stopPropagation();
        let hold_notifies = Object.assign([], $this.wms_class.comStage.state.objData.notifies);
        let notifies = Object.assign([], $this.wms_class.comStage.state.objData.notifies);
        notifies[i]['bookmark'] = notifies[i]['bookmark'] == 'yes' ? 'no' : 'yes';
        const wStage = {
            ...$this.wms_class.comStage.state,
            objData: {
                ...$this.wms_class.comStage.state.objData,
                notifies: notifies,
            }
        }
        await $this.wms_class.comStage.setState(wStage);
        await $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
        ServiceUser.instance.updateNotifies(notifies[i]['id'], {bookmark:notifies[i]['bookmark']})
        .then(async ({ data }) => {
            if (data.status) {
                // Util.toast(data.success)
            } else {
                Util.toast(data.error, 'error')
                const _wStage = {
                    ...$this.wms_class.comStage.state,
                    objData: {
                        ...$this.wms_class.comStage.state.objData,
                        notifies: hold_notifies,
                    }
                }
                await $this.wms_class.comStage.setState(_wStage);
                await $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
            }
        })
    }
    const deleteNotify = async(e, i) => {
        e.stopPropagation();
        let hold_notifies = Object.assign([], $this.wms_class.comStage.state.objData.notifies);
        let notifies = Object.assign([], $this.wms_class.comStage.state.objData.notifies);
        let notify = Object.assign({}, notifies[i]);
        notifies.splice(i, 1);
        const wStage = {
            ...$this.wms_class.comStage.state,
            objData: {
                ...$this.wms_class.comStage.state.objData,
                notifies: notifies,
            }
        }
        await $this.wms_class.comStage.setState(wStage);
        await $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
        ServiceUser.instance.deleteNotifies(notify.id)
        .then(async ({ data }) => {
            if (data.status) {
                Util.toast(data.success)
            } else {
                Util.toast(data.error, 'error')
                const _wStage = {
                    ...$this.wms_class.comStage.state,
                    objData: {
                        ...$this.wms_class.comStage.state.objData,
                        notifies: hold_notifies,
                    }
                }
                await $this.wms_class.comStage.setState(_wStage);
                await $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
            }
        })
    }
    const handleUpdate = async(e) => {
        if (e.scrollHeight - e.clientHeight > 0 && e.scrollTop > e.scrollHeight - e.clientHeight - 5 && !$this.wms_class.comStage.state.objData.loadingNotifies && $this.wms_class.comStage.state.objData.notifies.length < $this.wms_class.comStage.state.objData.notitotal) {
            const wStage = {
                ...$this.wms_class.comStage.state,
                objData: {
                    ...$this.wms_class.comStage.state.objData,
                    loadingNotifies: true,
                }
            }
            await $this.wms_class.comStage.setState(wStage);
            await $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
            $this.wms_class.getNotifies(async function(arg, data) {
                await $this.wms_class.react.setState({wms_class: arg});
            });
        }
    }
    const onFilterNotifies = async(field) => {
        let pagingNoti = Object.assign({}, $this.wms_class.comStage.state.objData.pagingNoti);
        pagingNoti[field] = !pagingNoti[field];
        const wStage = {
            ...$this.wms_class.comStage.state,
            objData: {
                ...$this.wms_class.comStage.state.objData,
                loadingNotifies: true,
                notifies: [],
                pagingNoti: pagingNoti,
            }
        }
        await $this.wms_class.comStage.setState(wStage);
        await $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
        $this.wms_class.getNotifies(async function(arg, data) {
            await $this.wms_class.react.setState({wms_class: arg});
        });
    }
    const markRead = async(e, i) => {
        e.stopPropagation();
        let _objData = Object.assign([], $this.wms_class.comStage.state.objData);
        let hold_notifies = Object.assign([], _objData.notifies);
        let notifies = Object.assign([], _objData.notifies);
        if (notifies[i]['isread'] == 'yes') {
            return;
        }
        let noticount = _objData.noticount;
        notifies[i]['isread'] = 'yes';
        const wStage = {
            ...$this.wms_class.comStage.state,
            objData: {
                ...$this.wms_class.comStage.state.objData,
                notifies: notifies,
                noticount: noticount.length > 2 ? noticount : Util.renderNumberPlus(parseInt(noticount) - 1),
            }
        }
        await $this.wms_class.comStage.setState(wStage);
        await $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
        ServiceUser.instance.updateNotifies(notifies[i]['id'], {isread:notifies[i]['isread']})
        .then(async ({ data }) => {
            if (data.status) {
                // Util.toast(data.success)
            } else {
                Util.toast(data.error, 'error')
                const _wStage = {
                    ...$this.wms_class.comStage.state,
                    objData: {
                        ...$this.wms_class.comStage.state.objData,
                        notifies: hold_notifies,
                        noticount: noticount,
                    }
                }
                await $this.wms_class.comStage.setState(_wStage);
                await $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
            }
        })
    }
    const onClickNotifies = async(e, i) => {
        let _objData = Object.assign([], $this.wms_class.comStage.state.objData);
        let notifies = Object.assign([], _objData.notifies);
        await markRead(e, i);
        await openModalTask(notifies[i]['task_id']);
    }
    const openModalTask = async(uid) => {
        let _index_task = _.findIndex($this.wms_class.comStage.state.objData.tasks, {
            uid: uid
        });
        let _index_stage = _.findIndex($this.wms_class.comStage.state.objData.columns, {
            step: $this.wms_class.comStage.state.objData.tasks[_index_task] ? $this.wms_class.comStage.state.objData.tasks[_index_task]['step'] : ''
        });
        if (_index_task > -1 && _index_stage > -1){
            await $this.header_self.openNotifies();
            await $this.react.props.data.toggleMTD(uid);
        }
    }
    return (
    <Popover className="stage-popover notifications" placement="bottom" target='popover-notifies' trigger="legacy" delay={{ show: 150, hide: 0 }} animation="true" isOpen={$this.header_self.state.isOpenNotifies} toggle={() => $this.header_self.openNotifies(false)} >
        <PopoverHeader className="bg-transparent d-flex gap-1" tag="div">
            <div className="me-auto">
                {$this.wms_class && $this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.header.notifications : "Notifications"}
            </div>
            <div className="d-flex align-items-center hover-effect-none bg-transparent border-0 btn btn-light p-0">
                <MdMarkUnreadChatAlt size="24" className={`hover-opacity-5per rounded-circle p-1 ${$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData.pagingNoti.unread ? 'bg-info text-white' : 'bg-light text-muted'}`} title={`Unread`} onClick={() => onFilterNotifies('unread')}/>
            </div>
            <div className="d-flex align-items-center hover-effect-none bg-transparent border-0 btn btn-light p-0">
                <MdOutlineAlternateEmail size="24" className={`hover-opacity-5per rounded-circle p-1 ${$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData.pagingNoti.mention ? 'bg-info text-white' : 'bg-light text-muted'}`} title={`Mention`} onClick={() => onFilterNotifies('mention')}/>
            </div>
        </PopoverHeader>
        <PopoverBody className="work-scroller p-0 bg-light"
            style={{
                width: '340px',
                'overflow': 'auto'
            }}
        >
            <Scrollbars
                onUpdate={handleUpdate}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                autoHeight
                autoHeightMin={`calc(100vh - 100px)`}
                autoHeightMax={`calc(100vh - 100px)`}
                thumbMinSize={30}
                universal={true}
                className='stage-odd-even'>
                {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData.notifies.length > 0 && (
                <CardBody className={``}>
                    {$this.wms_class.comStage.state.objData.notifies.map((notify, i) => {
                    let _listIcons = [];
                    _.forEach($this.wms_class.comStage.state.lstIcons, function(value, key) {
                        _listIcons = _.uniq(_.flatten([_listIcons, value.items]));
                    });
                    let icoid = parseInt(notify.icoid);
                    let bgrid = parseInt(notify.bgrid);
                    let _iconExtend = _.find(_listIcons, { id: icoid});
                    let _bgcolorExtend = Util.indexColorbyId($this.wms_class.comStage.state.lstColors, bgrid);
                    let _txtcolorExtend = _bgcolorExtend;
                    let _rgbcolorExtend = Util.hex2Rgb(_bgcolorExtend, 10);
                    _bgcolorExtend = _rgbcolorExtend ? _rgbcolorExtend.rgb.replace(/rgb/i, "rgba") : _bgcolorExtend.replace(/\)/i,',0.1)');
                    return (<ListGroup key={i} className={`p-3 pt-2 pb-2`}>
                        <InputGroup className={`hover-opacity-unset cursor-pointer ${notify.isread == 'no' ? 'opacity-75' : ''}`} onClick={(e) => onClickNotifies(e, i)}>
                            <div className={`d-flex align-items-start justify-content-start gap-3 text-black-50 w-100`}>
                                <div className={`d-flex align-items-start px-0`}>
                                    <div className={`border rounded-circle`} title={notify.isread == 'no' ? 'Mark read' : ''} onClick={(e) => markRead(e, i)}
                                        style={{backgroundColor: _bgcolorExtend, color: _txtcolorExtend}}>
                                        {_iconExtend && (<SVG src={_iconExtend.path}
                                            width={32}
                                            height={32}
                                            className={`me-auto d-flex align-self-center`}
                                        />)}
                                    </div>
                                </div>
                                <div className={`w-100`}>
                                    <div className={`bg-white mt-n2 p-3`}>
                                        <div className={`d-flex gap-1`}>
                                            <CardTitle tag="p" className={`text-uppercase text-black-50 me-auto ${notify.bookmark == 'yes' ? 'fw-bolder' : ''}`}>
                                                {moment(notify.created_at).locale($this.wms_class.comStage.state.objData.profiles.language === 'EN' ? 'en-gb' : 'vi').calendar(null, objConfig)}
                                            </CardTitle>
                                            <div className="d-flex align-items-center hover-effect-none bg-transparent border-0 btn btn-light p-0 opacity-0 unset-child">
                                                <MdBookmarkAdded size="24" className={`hover-opacity-5per rounded-circle p-1 ${notify.bookmark == 'yes' ? 'bg-info text-white' : 'bg-light text-muted'}`} title={`Bookmark`} onClick={(e) => bookmarkNotify(e, i)}/>
                                            </div>
                                            <div className="d-flex align-items-center hover-effect-none bg-transparent border-0 btn btn-light p-0 opacity-0 unset-child">
                                                <MdDelete size="24" className={`bg-light hover-opacity-5per rounded-circle text-muted p-1`} title={`Delete`} onClick={(e) => deleteNotify(e, i)}/>
                                            </div>
                                        </div>
                                        <CardTitle tag="span" className={`text-muted dangerouslySetInnerHTML`}>
                                            {notify.fullname}{notify.group == 'comment' ? (notify.has_mention ? $this.wms_class.comStage.state.Trans.notifies.mentioned : $this.wms_class.comStage.state.Trans.notifies.commented) : ''} <CardTitle tag="span" className={`text-muted`} dangerouslySetInnerHTML={{__html: notify.content}}></CardTitle>
                                        </CardTitle>
                                        <CardTitle tag="p" className={`text-black-50`}>
                                            {notify.taskname}
                                        </CardTitle>
                                        {notify.parent_content && (<div className={`bg-light mt-2 p-2`}>
                                            <CardText className={`ms-1 m-0 text-muted dangerouslySetInnerHTML`} dangerouslySetInnerHTML={{__html: notify.parent_content}}>
                                            </CardText>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </InputGroup>
                    </ListGroup>)
                    }
                    )}
                    {$this.wms_class.comStage && $this.wms_class.comStage.state &&$this.wms_class.comStage.state.objData.loadingNotifies && (
                    <div className={`p-3 pt-2`}>
                        <Spinner color="danger" className={`d-flex justify-content-center m-auto`}>
                            Loading...
                        </Spinner>
                    </div>)}
                </CardBody>)}
            </Scrollbars>
        </PopoverBody>
      </Popover>
    );
}
export {PopoverStyle, PopoverNotifies};