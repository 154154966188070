// import npm packge
import React, { useState, useRef } from "react";
import * as moment from "moment";
import $ from 'jquery';
import _ from 'lodash';
import 'moment/locale/vi';
import 'moment/locale/en-gb';
import { Card, CardHeader, CardBody, CardText, Button, ListGroup, InputGroup } from 'reactstrap';
import { EditorState, ContentState, convertToRaw, getDefaultKeyBinding } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
// import service api
import ServiceTinfo  from '../../services/ServiceTinfo';  
// import core class
import Util from '../../core/util';
// import com page
import { AvatarCom }  from './AvatarCom';
// import modal tool
//
// import style media
import { FaRegFaceSmile, FaRegFaceSurprise } from "react-icons/fa6";
import { FcBusinessman, FcBusinesswoman, FcLike, FcCheckmark, FcCloseUpMode } from "react-icons/fc";
import { MdOutlineAddReaction, MdOutlinePlayCircle, MdOutlineStopCircle, MdOutlineCancel, MdOutlineSend, //
MdOutlineQuickreply, MdOutlineEdit, MdOutlineDeleteOutline, MdOutlineSave } from "react-icons/md";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./default.css";
const Worklog = props => {
    const editorRef = useRef(null);
    const [sampleEditorContent, setSampleEditorContent] = useState(() =>
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('').contentBlocks))
    );
    const [sampleEditorContentEdit, setSampleEditorContentEdit] = useState(() =>
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('').contentBlocks))
    );
    const defaultReplyContent = {mention: null, parent_id: null, created_by: null, content: ''};
    const [replyContent, setReplyContent] = useState(defaultReplyContent);
    let objConfig = Util.getCalendarConfig(props.wms_class.objData.profiles.language);
    const listMentions = _.reject(props.wms_class.comStage.state.lstMembers, function(item) {
        item.text = item.fullname;
        item.value = item.fullname;
        item.url = '#'+item.uid;
        return !item.isMember;
    });
    const toolbarConfig = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
        inline: {
            inDropdown: true,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        blockType: {
            inDropdown: true,
        },
        fontSize: {
            inDropdown: true,
        },
        fontFamily: {
            inDropdown: true,
        },
        list: {
            inDropdown: true,
        },
        textAlign: {
            inDropdown: true,
        },
        history: {
            inDropdown: true,
        },
    };
    const hashConfig = { 
        trigger: '#',
        separator: ' ',
    };
    const keyBindingFn = (event, i = -1, action = 'add') => {
        if (event.key === "Tab") {
            return event.preventDefault();
        }
        if (event.key === "Enter") {
            event.preventDefault();
            if (action === 'add') {
                return sendTextContent();
            } else {
                return updateTextContent(i);
            }
        }
        return getDefaultKeyBinding(event);
    };
    const clearTextContent = () => {
        setSampleEditorContent(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('').contentBlocks)));
        setReplyContent(prev => ({...{}, ...defaultReplyContent}));
    }
    const sendTextContent = () => {
        let input_content = draftToHtml(convertToRaw(sampleEditorContent.getCurrentContent()), hashConfig).replace(/\n$/,"");
        let mentions = Util.getHrefValue(input_content);
        if (!props.wms_class.onsubmit) {
            Util.toast(props.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
            return;
        }
        clearTextContent();
        props.wms_class.onsubmit = false;
        let objData = {
            content: input_content,
            mentions: mentions
        }
        if (replyContent.parent_id) {
            objData.parent_id = replyContent.parent_id;
        }
        ServiceTinfo.instance.commentTinfo(props.uid, objData).then(({ data }) => {
            if (data.status) {
                //
            } else {
                Util.toast(data.error, 'error');
            }
            props.wms_class.onsubmit = true;
        })
    }
    const replyComment = (log) => {
        setReplyContent(prev => ({...{}, ...{parent_id: log.id, created_by: log.created_by, content: log.content}}));
        editorRef.current.focusEditor();
        let $block = $('#body-task-detail-' + props.uid);
        let $editor = $('#comment-task-editor-' + props.uid);
        $block.animate({
            scrollTop: $editor.get(0).offsetTop + ($block.get(0).clientHeight / 2)
        }, 1000);
    }
    const editComment = (i) => {
        let _taskWorklogs = Object.assign([], props.taskWorklogs);
        _.forEach(_taskWorklogs, function(o, j) { 
            if (i == j) {
                o.isEdit = !o.isEdit;
            } else {
                o.isEdit = false;
            }
        });
        props.setTaskWorklogs(prev => ([...[], ..._taskWorklogs]));
        setSampleEditorContentEdit(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(_taskWorklogs[i].content).contentBlocks)));
    }
    const closeEdit = (i) => {
        let _taskWorklogs = Object.assign([], props.taskWorklogs);
        _taskWorklogs[i].isEdit = false;
        props.setTaskWorklogs(prev => ([...[], ..._taskWorklogs]));
        setSampleEditorContentEdit(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('').contentBlocks)));
    }
    const updateTextContent = (i) => {
        if (!props.wms_class.onsubmit) {
            Util.toast(props.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
            return;
        }
        props.wms_class.onsubmit = false;
        let _taskWorklogs = Object.assign([], props.taskWorklogs);
        ServiceTinfo.instance.updateCommentTinfo(_taskWorklogs[i].id, {content: draftToHtml(convertToRaw(sampleEditorContentEdit.getCurrentContent()), hashConfig).replace(/\n$/,"")}).then(({ data }) => {
            if (data.status) {
                closeEdit(i);
            } else {
                Util.toast(data.error, 'error');
            }
            props.wms_class.onsubmit = true;
        })
    }
    const deleteComment = (i) => {
        let _taskWorklogs = Object.assign([], props.taskWorklogs);
        if (!props.wms_class.onsubmit) {
            Util.toast(props.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
            return;
        }
        props.wms_class.onsubmit = false;
        ServiceTinfo.instance.deleteCommentTinfo(_taskWorklogs[i].id).then(({ data }) => {
            if (data.status) {
                props.setTaskWorklogs([
                    ..._taskWorklogs.slice(0, i),
                    ..._taskWorklogs.slice(i + 1)
                ]);
                setSampleEditorContentEdit(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('').contentBlocks)));
            } else {
                Util.toast(data.error, 'error');
            }
            props.wms_class.onsubmit = true;
        })
    }
    const scrollToMessageParent = async(parent_id) => {
        await scrollToMessage($('#body-task-detail-' + props.uid), $('#task-comment-' + parent_id))
    }
    const scrollToMessage = ($block, $message, ms = 1000) => {
        if ($block.length && $message.get(0)) {
            $block.animate({
                scrollTop: $message.get(0).offsetTop + ($block.get(0).clientHeight / 2)
            }, ms);
        }
    }
    return (
        <>
            {(props.activeTabLog === 'showall' || props.activeTabLog === 'comment') && (
            <>
            <CardBody id={"comment-task-editor-" + props.uid} className={`d-flex pb-0`}>
                <div className={`form-control border-1 hover-effect mt-0 text-muted bg-white cursor-text edit-comment-task`}>
                    <Editor
                        ref={editorRef}
                        toolbar={toolbarConfig}
                        wrapperClassName="wrapper-class p-0"
                        editorClassName="editor-class described-comment"
                        toolbarClassName="toolbar-class"
                        wrapperStyle={{}}
                        editorStyle={{ minHeight: '85px', overflow: 'hidden'}}
                        editorState={sampleEditorContent}
                        onEditorStateChange={setSampleEditorContent}
                        mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: listMentions,
                        }}
                        keyBindingFn={(event) => keyBindingFn(event)}
                        hashtag={hashConfig}
                    />
                </div>
            </CardBody>
            {replyContent.parent_id && (
            <div className={`position-relative mt-2 ps-2`} style={{ zIndex: '10'}}>
                <CardText className={`ms-2 m-0 text-muted`}>
                    <span style={{background: 'var(--bs-gray-200)'}} className={'px-1'}>@{_.find(listMentions, { uid: replyContent.created_by }).fullname}</span>&nbsp; said
                </CardText>
                <CardText className={`ms-2 ps-2 m-0 mt-1 text-black-50 small card-text border border-start-1 border-end-0 border-top-0 border-bottom-0 border-primary log-content`} dangerouslySetInnerHTML={{__html: replyContent.content}}>
                </CardText>
            </div>
            )}
            {props.loadedTasklog && (
            <div className={`d-flex justify-content-between text-muted px-3 pt-1`} style={{ zIndex: '10'}}>
                <MdOutlineCancel size="27" title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.cancel  : 'Cancel'} className={`bg-light hover-effect-border rounded-circle text-muted p-1`} onClick={() => clearTextContent()}/>
                <MdOutlineSend size="27" title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.add_comment  : 'Add Comment'} className={`bg-light hover-effect-border rounded-circle text-muted p-1`} onClick={() => sendTextContent()}/>
            </div>
            )}
            </>
            )}
            {props.taskWorklogs && props.taskWorklogs.length > 0 && (
            <ListGroup className={`mt-0 p-3`} style={{ zIndex: '10'}}>
                {props.taskWorklogs.map((log, i) => { 
                    return (
                    <InputGroup key={i} id={"task-comment-" + log.id} className={`pb-3 task-worklog`}>
                        {log.group == 'worklog' && (
                        <>
                            <div className={`d-flex align-items-start justify-content-start gap-3 text-black-50 w-100`}>
                                <AvatarCom member={log} width={'32'} height={'32'} unsetborder={true}/>
                                <span style={{fontSize: 'var(--fs-13)'}} className={`d-flex align-self-start fw-bold`}>{log.fullname}</span>
                                <span style={{fontSize: 'var(--fs-13)'}} className={`d-flex align-self-start me-auto`}>{moment(log.created_at).locale(props.wms_class.objData.profiles.language === 'EN' ? 'en-gb' : 'vi').calendar(null, objConfig)}</span>
                            </div>
                            <div className={`ps-3 ms-3 w-100`}>
                                <div className={`bg-light ms-3 px-1 py-1 mt-n1`}>
                                    <CardText className={`ms-2 m-0 text-muted log-content`} dangerouslySetInnerHTML={{__html: log.content}}>
                                    </CardText>
                                </div>
                            </div>
                        </>
                        )}
                        {log.group == 'comment' && (
                        <>
                            <div className={`d-flex align-items-start justify-content-start gap-3 text-black-50 w-100`} >
                                <AvatarCom member={log} width={'32'} height={'32'} unsetborder={true}/>
                                <span style={{fontSize: 'var(--fs-13)'}} className={`d-flex align-self-start fw-bold`}>{log.fullname}</span>
                                <span style={{fontSize: 'var(--fs-13)'}} className={`d-flex align-self-start`}>{moment(log.created_at).locale(props.wms_class.objData.profiles.language === 'EN' ? 'en-gb' : 'vi').calendar(null, objConfig)}</span>
                                {log.is_update == 1 && (<strong style={{fontSize: 'var(--fs-13)'}} className={`d-flex align-self-start me-auto`}>({props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.edited : 'Edited'})</strong>)}
                                <div className={`d-flex align-self-start gap-1 comment-detail ms-auto`}>
                                    {props.wms_class.objData.profiles.uid == log.created_by && 
                                        (<MdOutlineDeleteOutline size="24" className={`d-none bg-light hover-effect rounded-circle p-1 text-muted cursor-pointer`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.deletelog  : 'Delete'} onClick={() => deleteComment(i)}/>)}
                                    {props.wms_class.objData.profiles.uid == log.created_by && 
                                        (<MdOutlineEdit size="24" className={`d-none bg-light hover-effect rounded-circle p-1 text-muted cursor-pointer`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.editlog  : 'Edit'} onClick={() => editComment(i)}/>)}
                                        <MdOutlineQuickreply size="24" className={`d-none bg-light hover-effect rounded-circle p-1 text-muted cursor-pointer`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.replylog  : 'Reply'} onClick={() => replyComment(log)}/>
                                </div>
                            </div>
                            <div className={`ps-3 ms-3 w-100`}>
                                {!log.isEdit && (
                                <div className={`bg-light ms-3 px-1 py-1 mt-n1`}>
                                    <CardText tag="div" className={`ms-2 m-0 text-muted log-content`} dangerouslySetInnerHTML={{__html: log.content}}>
                                    </CardText>
                                    {log.parent_id && log.parent_content && (
                                    <div className={`mt-2`}>
                                        <CardText tag="div" className={`ms-2 m-0 text-muted`}>
                                            <span style={{background: 'var(--bs-gray-200)'}} className={'px-1'}>@{_.find(listMentions, { uid: log.parent_created_by }).fullname}</span>&nbsp; said
                                        </CardText>
                                        <CardText tag="div" onClick={(e) => scrollToMessageParent(log.parent_id)} className={`log-content ms-2 ps-2 m-0 mt-1 text-black-50 small card-text border border-start-1 border-end-0 border-top-0 border-bottom-0 border-primary cursor-pointer`} dangerouslySetInnerHTML={{__html: log.parent_content}}>
                                        </CardText>
                                    </div>)}
                                </div>)}
                                {log.isEdit && (
                                <>
                                <CardBody className={`d-flex py-0`}>
                                    <div className={`form-control border-1 hover-effect mt-0 text-muted bg-white cursor-text`}>
                                        <Editor
                                            toolbar={toolbarConfig}
                                            wrapperClassName="wrapper-class p-0"
                                            editorClassName="editor-class described-comment"
                                            toolbarClassName="toolbar-class"
                                            wrapperStyle={{}}
                                            editorStyle={{ minHeight: '85px', overflow: 'hidden'}}
                                            editorState={sampleEditorContentEdit}
                                            onEditorStateChange={setSampleEditorContentEdit}
                                            mention={{
                                                separator: ' ',
                                                trigger: '@',
                                                suggestions: listMentions,
                                            }}
                                            keyBindingFn={(event) => keyBindingFn(event, i, 'edit')}
                                            hashtag={hashConfig}
                                        />
                                    </div>
                                </CardBody>
                                {log.parent_content && (
                                <div className={`mt-2`} style={{ zIndex: '10'}}>
                                    <CardText tag="div" className={`ms-3 m-0 text-muted`}>
                                        <span style={{background: 'var(--bs-gray-200)'}} className={'px-1'}>@{_.find(listMentions, { uid: log.parent_created_by }).fullname}</span>&nbsp; said
                                    </CardText>
                                    <CardText tag="div" className={`log-content ms-3 ps-2 m-0 mt-1 text-black-50 small card-text border border-start-1 border-end-0 border-top-0 border-bottom-0 border-primary`} dangerouslySetInnerHTML={{__html: log.parent_content}}>
                                    </CardText>
                                </div>)}
                                <div className={`position-relative d-flex justify-content-end text-muted p-2 gap-1`} style={{ zIndex: '10'}}>
                                    <MdOutlineCancel size="27" title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.cancel  : 'Cancel'} className={`bg-light hover-effect-border rounded-circle text-muted p-1 cursor-pointer`} onClick={() => closeEdit(i)}/>
                                    <MdOutlineSave size="27" title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.profile.update  : 'Update'} className={`bg-light hover-effect-border rounded-circle text-muted p-1 cursor-pointer`} onClick={() => updateTextContent(i)}/>
                                </div>
                                </>
                                )}
                            </div>
                        </>
                        )}
                        {log.group == 'tracktime' && (
                        <>
                            <div className={`d-flex align-items-start justify-content-start gap-3 text-black-50 w-100`}>
                                {log.is_stop > 0 && (<MdOutlineStopCircle size="32" className={`btn btn-transparent btn-sm outline-transparent rounded-circle btn-danger outline-danger text-white p-1`} />)}
                                {log.is_stop == 0 && (<MdOutlinePlayCircle size="32" className={`btn btn-transparent btn-sm outline-transparent rounded-circle btn-danger outline-danger text-white p-1`} />)}
                                <span style={{fontSize: 'var(--fs-13)'}} className={`d-flex align-self-start fw-bold`}>{log.fullname}</span>
                                <span style={{fontSize: 'var(--fs-13)'}} className={`d-flex align-self-start me-auto`}>{moment(log.created_at).locale(props.wms_class.objData.profiles.language === 'EN' ? 'en-gb' : 'vi').calendar(null, objConfig)}</span>
                            </div>
                            <div className={`ps-3 ms-3 w-100`}>
                                <div className={`bg-light ms-3 px-1 py-1 mt-n1`}>
                                    <CardText className={`ms-2 m-0 text-muted log-content`} dangerouslySetInnerHTML={{__html: log.content}}>
                                    </CardText>
                                </div>
                            </div>
                        </>
                        )}
                    </InputGroup>)
                }
                )}
                {props.continueMore && props.taskWorklogs && props.taskWorklogs.length > 0 && (
                    <div className={`text-center`}>
                        <Button outline size="sm" color="info" onClick={() => {props.loadMoreTlogs()}}>
                            {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.loadmore : 'Load more'}
                        </Button>
                    </div>
                )}
            </ListGroup>
            )}
            {(props.activeTabLog === 'comment' || props.activeTabLog === 'tracktime') && props.taskWorklogs && props.taskWorklogs.length == 0 && (
            <ListGroup className={`mt-0 p-3`} style={{ zIndex: '10'}}>
                <div className={`d-flex align-items-start justify-content-center gap-3 text-black-50 w-100`}>
                    <span style={{fontSize: 'var(--fs-13)'}} className={`d-flex align-self-start fw-bold`}>{props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.content_null : 'No Data'}</span>
                </div>
            </ListGroup>
            )}
        </>
    )
};
function ActivityCom(props) {
    const [isShowToolbar, setIsShowToolbar] = useState(true);
    const collapeExpandToolbar = () => {
        $('.edit-comment-task > div.rdw-editor-wrapper > div.rdw-editor-toolbar').css("display", isShowToolbar ? "none" : "flex");
        setIsShowToolbar(!isShowToolbar)
    }
    const changeTabLogs = (tab) => {
        setIsShowToolbar(true)
        props.changeTabLogs(tab);
    }
	return (
        <Card className={`border-0 rounded-0 pt-1 conversation-task`}>
            <CardHeader
                className={`border-0 rounded-0 fs-6 d-flex justify-content-start py-1 text-primary gap-1`}
            >
                <div className={`me-auto d-flex align-self-center`}>{props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.activity : 'Activity'}</div>
                <div className={`d-flex align-self-center`}>
                    <Button onClick={() => changeTabLogs('showall')}
                        style={{fontSize: 'var(--fs-11)'}}
                        className={`border-0 py-0 fw-bold ${props.activeTabLog === 'showall' ? 'active' : ''}`}
                        color="secondary" size="sm" outline>
                        {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.showall : 'Show All'}
                    </Button>
                    <Button onClick={() => changeTabLogs('worklog')}
                        style={{fontSize: 'var(--fs-11)'}}
                        className={`border-0 py-0 fw-bold ${props.activeTabLog === 'worklog' ? 'active' : ''}`}
                        color="secondary" size="sm" outline>
                        {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.worklog : 'Work Log'}
                    </Button>
                    <Button onClick={() => changeTabLogs('comment')}
                        style={{fontSize: 'var(--fs-11)'}}
                        className={`border-0 py-0 fw-bold ${props.activeTabLog === 'comment' ? 'active' : ''}`}
                        color="secondary" size="sm" outline>
                        {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.comments : 'Comments'}
                    </Button>
                    <Button onClick={() => changeTabLogs('tracktime')}
                        style={{fontSize: 'var(--fs-11)'}}
                        className={`border-0 py-0 fw-bold ${props.activeTabLog === 'tracktime' ? 'active' : ''}`}
                        color="secondary" size="sm" outline>
                        {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.tracktime : 'Track Time'}
                    </Button>
                </div>
                <div className={`d-flex align-self-center ${props.activeTabLog === 'showall' || props.activeTabLog === 'comment' ? '' : 'invisible'}`}>
                    <div className={`d-flex justify-content-end mx-3 text-black-50 cursor-pointer`} onClick={(e) => collapeExpandToolbar()}>
                        {isShowToolbar ? (<FaChevronUp/>) : (<FaChevronDown/>)}
                    </div>
                </div>
            </CardHeader>
            <Worklog wms_class={props.wms_class} uid={props.Tinfo.uid} setTaskWorklogs={props.setTaskWorklogs} taskWorklogs={props.taskWorklogs} changeTabLogs={props.changeTabLogs} activeTabLog={props.activeTabLog} loadMoreTlogs={props.loadMoreTlogs} continueMore={props.continueMore} loadedTasklog={props.loadedTasklog} />
        </Card>
    );
};

export { ActivityCom };