// import npm packge
import React, { useState, useEffect } from "react";
import { Input, InputGroup } from 'reactstrap';
import Select, { components, StylesConfig } from 'react-select';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import * as moment from "moment";
// import service api
//
// import core class
import Util from '../../core/util';
// import com page
//
// import modal tool
//
// import style media
function CustomElement(props) {
    const switchElement = (params) => {
        useEffect(() => {
            if (params.element == 'inputdate' && params.comStage && params.comStage.state) {
                if (params.value && moment(params.value).isValid()) {
                    setStartDate(new Date(params.value));
                }
            }
        }, []);
        const onSelectedTag = (tag) => {
            params.setValueTagCustomField(tag.name, params.index)
        }
        let lstTags = _.map(Object.assign([], _.filter(params.lstTags, function(o){ return o.status == 'active' && o.type == 'selecttags';})), function(p) {
            p.label = p.name;
            p.value = p.id;
            return p;
        });
        const [startDate, setStartDate] = useState(null);
        const onChangeDate = (date) => {
            setStartDate(date);
            params.setValueTagCustomField(moment(date).format("YYYY-MM-DD"), params.index)
        }
            
        switch(params.element) {
            case 'input':
                return <Input disabled={params.disabled == true} className={params.className} name={params.name} value={params.value ? params.value : ''} type="text" onChange={(e) => params.onChange(e, params.index)} onBlur={(e) => params.onBlur(e, params.index)} />;
            case 'textarea':
                return <Input disabled={params.disabled == true} className={params.className} name={params.name} value={params.value ? params.value : ''} type="textarea" rows={props.rows & props.rows < 4 ? props.rows : 3} style={{resize: 'none'}} onChange={(e) => params.onChange(e, params.index)} onBlur={(e) => params.onBlur(e, params.index)} />;
            case 'selecttags':
                return <InputGroup>
                            <div className={`flex-grow-1`}>
                                <Select
                                    value={null}
                                    options={lstTags}
                                    isClearable={false}
                                    onChange={onSelectedTag}
                                    className={`cuson-selects-value`}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                            <Input disabled={params.disabled == true} className={'h-auto ' + params.className} name={params.name} value={params.value ? params.value : ''} type="text" onChange={(e) => params.onChange(e, params.index)} onBlur={(e) => params.onBlur(e, params.index)} />
                        </InputGroup>;
            case 'inputdate':
                return <DatePicker
                        selected={startDate}
                        onChange={onChangeDate} 
                        dateFormat={params.comStage.state.objData.workspace.date_fm}
                        className={'form-control ps-2 pe-2 ' + params.className}
                        readOnly={params.disabled == true}
                    />;
            default:
                return '';
        }
    }
	return (
        <>
            {switchElement(props)}
        </>
    );
};

export { CustomElement };