// import npm packge
import React, { useState } from 'react';
import {
    Form, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, //
    Card, CardHeader, CardBody, CardImg, CardText, ListGroup, ListGroupItem, InputGroup, InputGroupText, //
    UncontrolledDropdown, Badge, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle, //
    UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody
} from 'reactstrap';
// import service api
import ServiceUser  from '../../services/ServiceUser';
// import core class
import Util from '../../core/util';
// import com page
import {UploadAndDisplayImage} from './../Widget/wmsUploadDisplay';
// import modal tool
//
// import style media
import SVG from 'react-inlinesvg';
import { FcBusinessman, FcBusinesswoman, FcLike, FcCheckmark, FcCloseUpMode } from "react-icons/fc";
import './default.css';
// coding
function AccountProfile($this) {
    const [modal, setModal] = useState(false);
    let [ProfileDetail, setProfile] = useState($this.wms_class.objData.profiles);
    // $this.header_self.toggleModalProfile = () => setModal(!modal);
    $this.header_self.toggleModalProfile = () => {
        if (!modal) {
        	let _profile = $this.wms_class.objData.profiles;
            setProfile(_profile);
        }
        setModal(!modal);
    };
    const handleChange = (event) => {
    	event.preventDefault();
    	const {name, value} = event.target;
        setProfile(prev => ({...prev, [name]: value }));
    }
    const updateUser = (e) => {
    	e.preventDefault();
    	if (ProfileDetail.password != '' && ProfileDetail.password == '') {
    		return Util.toast("Please fill input confirm password!", 'error');
    	}
    	if (ProfileDetail.password != '' && ProfileDetail.password != '' && ProfileDetail.password != ProfileDetail.password) {
    		return Util.toast("Confirm Password not match!", 'error');
    	}
    	const {fullname, email, password} = ProfileDetail;
    	const data = {
    		fullname: fullname,
    		username: email,
    		avatar: $this.wms_class.objData.profiles.avatar,
    	};
    	if (password != '') {
    		data.password = password;
    	}
    	ServiceUser.instance.updateUser($this.wms_class.comStage.state.objData.profiles.uid, data)
        .then(({ data }) => {
            if (data.status) {
                Util.toast(data.success);
                let _idx_mem = Util.indexMembers($this.wms_class.lstMembers, $this.wms_class.objData.profiles.uid);
	            if (_idx_mem){
		            $this.wms_class.lstMembers[_idx_mem].fullname = ProfileDetail.fullname;
		            $this.wms_class.lstMembers[_idx_mem].email = ProfileDetail.email;
		        }
                $this.wms_class.objData.profiles.fullname = ProfileDetail.fullname;
	            $this.wms_class.objData.profiles.email = ProfileDetail.email;
	            const profileStage = {
			        ...$this.wms_class.comStage.state,
			        objData: {
			            ...$this.wms_class.comStage.state.objData,
			            profiles: $this.wms_class.objData.profiles
			        },
			        lstMembers: $this.wms_class.lstMembers
			    }
			    $this.wms_class.comStage.setState(profileStage);
			    $this.wms_class.react.setState({ wms_class: $this.wms_class.comStage });
			    setProfile(ProfileDetail);
			    $this.header_self.toggleModalProfile();
            } else {
                Util.toast(data.error, 'error')
            }
        })
    }
    return (
        <Modal isOpen={modal} toggle={$this.header_self.toggleModalProfile} {...$this} size={`lg`} scrollable={true}
			>
		    <ModalHeader toggle={$this.header_self.toggleModalProfile}
		    	className={`px-2`}>
				{$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.profile.account : 'Profile Account'}
		    </ModalHeader>
    		<Form onSubmit={updateUser} className={`mb-0`} autoComplete={'off'}>
			    <ModalBody className={`d-flex gap-3 pb-0 px-2`}>
			    	<div className={`maintask-left h-100`} style={{width: 'var(--per-wi-66)'}}>
		    			<FormGroup>
		    				<Label>
		    					{$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.profile.fullname : 'Fullname'}
		    				</Label>
						    <Input name="fullname" placeholder={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.profile.fullname : 'Fullname'} type="text" value={ProfileDetail.fullname} onChange={handleChange} required />
					    </FormGroup>
					    <FormGroup>
						    <Label>
						    	{$this.wms_class.comStage ? $this.wms_class.comStage.state.Trans.profile.username : 'Username'}
						    </Label>
					    	<Input name="username" placeholder={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.profile.username : 'Username'} type="text" value={ProfileDetail.email} onChange={handleChange} required />
					    </FormGroup>
					    <FormGroup>
						    <Label>
						    	{$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.profile.password : 'Password'}
						    </Label>
						    <Input name="password" placeholder={$this.wms_class.comStage  && $this.wms_class.comStage.state? $this.wms_class.comStage.state.Trans.profile.password : 'Password'} type="password" onChange={handleChange} />
					    </FormGroup>
					    <FormGroup>
						    <Label>
						    	{$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.profile.confirm_password : 'Confirm Password'}
						    </Label>
						    <Input name="confirmpassword" placeholder={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.profile.confirm_password : 'Confirm Password'} type="password" onChange={handleChange} />
					    </FormGroup>
			    	</div>
			    	<div className={`maintask-right h-100`} style={{width: 'var(--per-wi-34)'}}>
			    		<UploadAndDisplayImage className="text-center p-3" wms_class={$this.wms_class} react={$this.react} account_self={this}/>
			    	</div>
			    </ModalBody>
				<ModalFooter className={`px-2`}>
		          	<Button color="primary" type="submit">
		            	{$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.profile.update : 'Update'}
		          	</Button>
		        </ModalFooter>
		    </Form>
		</Modal>
    );
};
export { AccountProfile };